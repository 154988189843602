import React from 'react';
import {
  Box,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  useColorModeValue
} from '@chakra-ui/react';

const questions = [
  {
    question: "How does this work?",
    answer:
      "We provide easy and best-in-class scraping solutions to get data in a simple manner. No weird pricing, no wait times—just subscribe to a plan and start collecting data."
  },
  {
    question: "What is a credit?",
    answer:
      "A credit is a unit for the amount a request costs. The amount of credits per request can vary. The Google Maps data scraper costs 50 credits per request, while the LinkedIn and website scraper only cost 1 per request."
  },
  {
    question: "What happens if I max out my usage?",
    answer:
      "If you max out your usage on a free plan, you will not be able to use any more features. If you max out your usage on a paid plan, you will go over and be charged a rate for the overage at the end of the month."
  },
  {
    question: "When will I be charged for overages?",
    answer: "You will be charged at the end of the monthly period."
  },
  {
    question: "What happens if I want to upgrade?",
    answer:
      "If you want to upgrade, we recommend maxing out your usage on your current plan and then upgrading. When switching plans, you will have to pay the full price, and your usage will be reset to 0. For example, if you subscribe on May 1st and use your 3,000 credits on the basic tier by May 15th, upgrading to the pro tier will reset your subscription period to start on May 15th with 15,000 credits, and it will reset your total to 0."
  },
  {
    question: "If I am 2 weeks into my plan, how can I upgrade?",
    answer:
      "We recommend using your usage and then upgrading. If you still have 1,000 rows left, for example, when you move to a higher plan, they will not roll over; your usage will be reset to 0."
  },
  {
    question: "Can I cancel my plan?",
    answer:
      "Yes! You can cancel at any time by heading into the dashboard of your account, going to subscription options, and managing your subscription in the Stripe portal. From there, you can cancel your subscription."
  }
];

const PricingFAQ = () => {
  const boxBg = useColorModeValue('gray.100', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const borderColor = useColorModeValue('gray.300', 'gray.600');

  return (
    <Box
      bg={boxBg}
      py={10}
      maxW="1200px"
      mx="auto"
      px={{ base: 4, md: 8 }}
      borderRadius="md"
      boxShadow="xl"
      mt={10}
      mb={100}
    >
      <VStack spacing={8} align="stretch">
        <Heading
          as="h1"
          size="xl"
          textAlign="center"
          color={headingColor}
          mb={6}
        >
          Frequently Asked Questions
        </Heading>
        <Accordion allowToggle>
          {questions.map((faq, index) => (
            <AccordionItem
              key={index}
              borderColor={borderColor}
              _last={{ borderBottomWidth: 0 }}
            >
              <h2>
                <AccordionButton

                  px={4}
                  py={2}
                >
                  <Box
                    flex="1"
                    textAlign="left"
                    fontWeight="semibold"
                    fontSize={{ base: 'md', md: 'lg' }}
                  >
                    ❔ {faq.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                px={4}
                color={textColor}
                fontSize={{ base: 'sm', md: 'md' }}
              >
                {faq.answer}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </VStack>
    </Box>
  );
};

export default PricingFAQ;
