import React from 'react';
import { Box, Heading, Text, Button, Stack, useBreakpointValue, Center } from '@chakra-ui/react';

function HomeAPI() {
  const buttonSize = useBreakpointValue({ base: 'md', md: 'lg' });

  return (
    <Center>
      <Box py={24} px={4} w="800px">
        <Stack direction={{ base: 'column', md: 'row' }} spacing={8} alignItems="center" justifyContent="space-between">
          <Box maxW={{ base: 'full', md: '50%' }} textAlign={{ base: 'center', md: 'left' }}>
            <Heading as="h2" size="xl" mb={4}>
              Use The API
            </Heading>
            <Box h={6} bgGradient="linear(to-r, blue.300, blue.600)" borderWidth={3} borderRightWidth={6} borderBottomWidth={6} borderColor="#222222" borderRadius={25} mb={10}></Box>
            <Text fontSize="xl" mb={4}>
              Explore our API and start integrating ScrapeTable into your applications.
            </Text>
          </Box>
          <Box maxW={{ base: 'full', md: '50%' }} textAlign={{ base: 'center', md: 'right' }}>
            <Button
              as="a"
              href="https://docs.scrapetable.com/api-reference"
              target="_blank"
              rel="noopener noreferrer"
              colorScheme="blue"
              size={buttonSize}
            >
              View the Docs
            </Button>
          </Box>
        </Stack>
      </Box>
    </Center>
  );
}

export default HomeAPI;