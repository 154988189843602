// GoogleMap.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Text,
  Spinner,
  Heading,
  Spacer,
  Image,
} from "@chakra-ui/react";
import { ChevronLeftIcon, DeleteIcon } from "@chakra-ui/icons";
import googleLogo from '../../../../../assets/google.png';
import { useNavigate } from "react-router-dom";

const Maps = ({ userPlan, toast, savedUrls, setSavedUrls }) => {
  const [userLocation, setUserLocation] = useState({
    lat: 40.7128,
    lng: -74.0059,
  });
  const [mapUrl, setMapUrl] = useState("");
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [inputUrl, setInputUrl] = useState("");

  const mapRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const onMapIdle = useCallback(() => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      const lat = center.lat();
      const lng = center.lng();
      const zoom = mapRef.current.getZoom();
      setMapUrl(`https://www.google.com/maps/@${lat},${lng},${zoom}z`);
    }
  }, []);

  useEffect(() => {
    if (isLoaded && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setIsMapLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setIsMapLoading(false);
        }
      );
    } else {
      setIsMapLoading(false);
    }
  }, [isLoaded]);

  const handleSaveUrl = () => {
    const maxUrls = userPlan === "free" ? 2 : 10;
    if (savedUrls.length < maxUrls) {
      setSavedUrls([...savedUrls, mapUrl]);
    } else {
      toast({
        title: "Maximum URLs Reached",
        description: `You have reached the maximum of ${maxUrls} URLs allowed for your plan.`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePasteUrl = () => {
    const maxUrls = userPlan === "free" ? 2 : 10;
    const pattern = /@(-?\d+\.\d+),(-?\d+\.\d+),(\d+)z/;
    const match = inputUrl.match(pattern);

    if (match && savedUrls.length < maxUrls) {
      setSavedUrls([...savedUrls, inputUrl]);
      setInputUrl("");
    } else if (savedUrls.length >= maxUrls) {
      toast({
        title: "Maximum URLs Reached",
        description: `You have reached the maximum of ${maxUrls} URLs allowed for your plan.`,
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      alert("Invalid URL format. Please enter a valid Google Maps URL.");
    }
  };

  const handleDeleteUrl = (index) => {
    const newSavedUrls = [...savedUrls];
    newSavedUrls.splice(index, 1);
    setSavedUrls(newSavedUrls);
  };

  const navigate = useNavigate(); // Initialize useNavigate

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Heading mb={4}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon boxSize={6} />}
            onClick={handleBackClick}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            _hover={{ transform: 'translateX(-4px)' }}
            fontSize="xl"
            tabIndex={-1}
          />
          <Image src={googleLogo} alt="Google logo" boxSize="40px" mr={4} />
          Google Maps Search Data
          <Spacer />
        </Flex>
      </Heading>
      {isLoaded ? (
        isMapLoading ? (
          <Flex justify="center" align="center" height="450px">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Box
            borderRadius="25px" // Added border radius
            overflow="hidden"   // Ensures the map respects the border radius
            boxShadow="md"      // Optional: Adds a subtle shadow for better visuals
            width="850px"
            height="450px"
            mx="auto"           // Centers the map horizontally
          >
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "100%" }} // Adjusted to fill the Box
              center={userLocation}
              zoom={14}
              onIdle={onMapIdle}
              onLoad={(map) => {
                mapRef.current = map;
              }}
            >
              <Marker position={userLocation} />
            </GoogleMap>
          </Box>
        )
      ) : (
        <Flex justify="center" align="center" height="450px">
          <Spinner size="xl" />
        </Flex>
      )}

      <Flex mt={4} alignItems="center">
        <Button
          colorScheme="green"
          mr={4}
          onClick={handleSaveUrl}
          fontFamily="Poppins"
          w={166}
          borderColor="#222222"
          borderWidth="3px"
          borderRadius={10}
          borderBottomWidth={6}
          borderRightWidth={6}
        >
          Save URL
        </Button>
        <Box flex={1} bg="gray.300" borderRadius="xl" px={4} py={2}>
          <Text fontFamily="Poppins" isTruncated color="gray.500">
            {mapUrl}
          </Text>
        </Box>
      </Flex>

      <Box my={4}>
        <Flex alignItems="center" mb={2}>
          <Button
            onClick={handlePasteUrl}
            fontFamily="Poppins"
            colorScheme="blue"
            mr={4}
            w={200}
            borderColor="#222222"
            borderWidth="3px"
            borderRadius={10}
            borderBottomWidth={6}
            borderRightWidth={6}
          >
            Paste URL
          </Button>
          <Input
            value={inputUrl}
            onChange={(e) => setInputUrl(e.target.value)}
            placeholder="Enter a Google Maps URL"
            fontFamily="Poppins"
            borderRadius="10"
            bg="gray.100"
          />
        </Flex>
        <Text fontWeight="bold" mb={2} fontFamily="Poppins" fontSize={20}>
          Saved URLs:
        </Text>
        {savedUrls.length === 0 ? (
          <Text fontFamily="Poppins">
            Please add a URL with the map or enter in a URL.
          </Text>
        ) : (
          savedUrls.map((url, index) => (
            <Flex key={index} alignItems="center" mb={2}>
              <IconButton
                aria-label="Delete URL"
                icon={<DeleteIcon />}
                onClick={() => handleDeleteUrl(index)}
                colorScheme="red"
                mr={2}
                p={4}
                borderColor="#222222"
                borderWidth="3px"
                borderRadius={10}
                borderBottomWidth={6}
                borderRightWidth={6}
              />
              <Box flex={1} bg="gray.300" borderRadius="xl" px={4} py={2}>
                <Text fontFamily="Poppins" isTruncated color="gray.500">
                  {url}
                </Text>
              </Box>
            </Flex>
          ))
        )}
      </Box>
    </Box>
  );
};

export default Maps;
