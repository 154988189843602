import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Grid,
  GridItem,
  Link,
  VStack,
  Tooltip,
  HStack,
} from "@chakra-ui/react";
import logo from "../../assets/logo.png";
import { FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from "react-icons/fa";

const Footer = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 3000);
    } else {
      setShowTooltip(false);
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  return (
    <Box py={10} px={4} bg="gray.100">
      <Grid
        templateColumns={["1fr", "1fr", "repeat(3, 1fr)"]}
        gap={8}
        maxW="1200px"
        mx="auto"
      >
        <GridItem>
          <Flex
            alignItems="center"
            mb={4}
            justifyContent={["center", "center", "flex-start"]}
          >
            <Tooltip
              label="how the turntables"
              isOpen={showTooltip}
              borderRadius={10}
            >
              <Link href="/" _hover={{ textDecoration: "none" }}>
                <Image
                  src={logo}
                  alt="Logo"
                  h="48px"
                  mr={3}
                  transition="transform 0.3s ease-in-out"
                  _hover={{ transform: "rotate(180deg)" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </Link>
            </Tooltip>
            <Link href="/" _hover={{ textDecoration: "none" }}>
              <Text
                fontSize="2xl"
                fontWeight="bold"
                textAlign={["center", "center", "left"]}
              >
                Scrape Table
              </Text>
            </Link>
          </Flex>
          <Text mb={6} textAlign={["center", "center", "left"]}>
            The simplest and easiest way to get data from across the internet.
          </Text>
        </GridItem>

        <GridItem>
          <Text fontSize="xl" fontWeight="bold" align="center" mb={4}>
            Pages
          </Text>
          <VStack align="center" spacing={2}>
            <Link href="/">Home</Link>
            <Link href="/data">Sources</Link>
            <Link href="/pricing">Pricing</Link>
            <Link href="/studio">Studio</Link>
            <Link href="https://scrapetable.tolt.io/">Affiliates</Link>
            <Link href="/contact">Contact</Link>
            <Link href="https://docs.scrapetable.com" isExternal>
              Docs
            </Link>
          </VStack>
        </GridItem>

        <GridItem textAlign={["center", "center", "left"]}>
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Lets Connect
          </Text>
          <VStack spacing={4} align={["center", "center", "stretch"]}>
            <HStack
              spacing={4}
              justify={["center", "center", "flex-start"]}
            >
              <Link
                href="https://www.linkedin.com/company/scrapetable"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin size="30px" />
              </Link>
              <Link
                href="https://www.tiktok.com/@scrapetable"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTiktok size="30px" />
              </Link>
              <Link
                href="https://www.instagram.com/scrapetable"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram size="30px" />
              </Link>
              <Link
                href="https://www.youtube.com/@scrapetable"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size="30px" />
              </Link>
            </HStack>
            <Text>
              <Text as="span" fontWeight="bold">
                Email:{" "}
              </Text>
              <Link
                href="mailto:contact@scrapetable.com"
                target="_blank"
                rel="noopener noreferrer"
                color="blue.500"
              >
                contact@scrapetable.com
              </Link>
            </Text>
          </VStack>
        </GridItem>
      </Grid>
      <Text textAlign="center" mt={8}>
        © 2024 Scrape Table. All rights reserved. | {" "}
        <Link href="/privacy-policy">Privacy Policy</Link> | {" "}
        <Link href="/terms-of-service">Terms of Service</Link>
      </Text>
    </Box>
  );
};

export default Footer;