import React, { useState, useEffect } from "react";
import { Box, useToast } from "@chakra-ui/react";
import Maps from "./Maps";
import { useAuth } from "../../../../utils/AuthContext";
import Filter from "./Filter";

const Google = () => {
  const [savedUrls, setSavedUrls] = useState([]);

  const toast = useToast();

  const { userPlan, isAuthenticated, loading } = useAuth();

  useEffect(() => {
    console.log("Saved URLs:", savedUrls);
  }, [savedUrls]);

  useEffect(() => {
    if (!loading) {
      if (isAuthenticated) {
        console.log("User Plan:", userPlan);
      } else {
        console.log("No authenticated user.");
      }
    }
  }, [userPlan, isAuthenticated, loading]);

  return (
    <Box maxW="850px" mx="auto" mt={10} mb={100}>
      <Maps
        userPlan={userPlan}
        toast={toast}
        savedUrls={savedUrls}
        setSavedUrls={setSavedUrls}
      />
      <Filter savedUrls={savedUrls} /> 
    </Box>
  );
};

export default Google;
