import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import TermsMain from './TermsMain';

function Terms() {
  return (
    <>
      <Header />
      <TermsMain />
      <Footer/>
    </>
  );
}

export default Terms;
