import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import DataSources from './DataSources';

function Data() {
  return (
    <>
      <Header />
      <DataSources />
      <Footer/>
    </>
  );
}

export default Data;
