// Results.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import * as XLSX from 'xlsx';
import { useAuth } from '../../utils/AuthContext';
import axios from 'axios';
import googleIcon from '../../../assets/google.png';
import websiteIcon from '../../../assets/website.png';
import markerIcon from '../../../assets/marker.png';

const Results = () => {
  const { frontendApiKey, isAuthenticated, loading } = useAuth();
  const [results, setResults] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  // Pagination and Sorting State Variables
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('timestamp');
  const [order, setOrder] = useState('desc');
  const [totalPages, setTotalPages] = useState(1);

  // Filtering State Variable
  const [selectedRoutes, setSelectedRoutes] = useState([]);

  // Route Mappings
  const routeMappings = {
    '/internal/maps/search-bulk': {
      name: 'Google Maps Search Data',
      icon: googleIcon,
    },
    '/internal/maps/enrich-emails': {
      name: 'Google Maps Data With Emails',
      icon: websiteIcon,
    },
    '/internal/get-maps-data-enrich': {
      name: 'Get Maps Data',
      icon: markerIcon,
    },
  };

  // Get all possible route keys for filtering
  const allRoutes = Object.keys(routeMappings);

  useEffect(() => {
    const fetchResults = async () => {
      if (isAuthenticated && frontendApiKey) {
        setFetching(true);
        setError(null);
        const apiBaseUrl = process.env.REACT_APP_API_FETCH_URL;
        try {
          const response = await axios.post(
            `${apiBaseUrl}/fetch-results`,
            {
              apiKey: frontendApiKey,
              page,
              limit: 10,
              sortBy,
              order,
              routes: selectedRoutes, // Include selected routes in the request
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          const { results: fetchedResults, totalPages } = response.data;
          setResults(fetchedResults);
          setTotalPages(totalPages);
        } catch (err) {
          console.error('Error fetching results:', err);
          setError(
            err.response?.data?.message || 'Failed to fetch results.'
          );
        } finally {
          setFetching(false);
        }
      }
    };

    fetchResults();
  }, [
    isAuthenticated,
    frontendApiKey,
    page,
    sortBy,
    order,
    selectedRoutes,
  ]);

  // Download functions
  const downloadJSON = (data, fileName) => {
    try {
      const json = JSON.stringify(data, null, 2);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading JSON:', error);
    }
  };

  const downloadCSV = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error('CSV download expects an array of objects.');
        return;
      }

      const csvRows = [];
      const headers = Object.keys(data[0] || {});
      csvRows.push(headers.join(','));

      data.forEach((row) => {
        const values = headers.map((header) => {
          const escaped = ('' + row[header]).replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      });

      const csvContent = csvRows.join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const downloadExcel = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error('Excel download expects an array of objects.');
        return;
      }

      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  };

  const handleDownload = (result, format) => {
    const epochTimestamp = result.timestamp
      ? new Date(result.timestamp).getTime()
      : Date.now();

    const sanitizedRoute = sanitizeFileName(result.route);
    let fileName = `result_${sanitizedRoute}_${epochTimestamp}`;
    switch (format) {
      case 'json':
        fileName += '.json';
        downloadJSON(result.data, fileName);
        break;
      case 'csv':
        fileName += '.csv';
        downloadCSV(result.data, fileName);
        break;
      case 'excel':
        fileName += '.xlsx';
        downloadExcel(result.data, fileName);
        break;
      default:
        break;
    }
  };

  const sanitizeFileName = (name) => {
    return name.replace(/[^a-z0-9_-]/gi, '_');
  };

  if (loading || fetching) {
    return (
      <Box textAlign='center' mt={10}>
        <Spinner size='xl' />
        <Text mt={4}>Loading...</Text>
      </Box>
    );
  }

  return (
    <Box maxW='700px' mx='auto' h='calc(100vh - 200px)'>
      <Heading as='h1' size='xl' mb={4}>
        Results
      </Heading>

      {isAuthenticated ? (
        <Box maxHeight='calc(100vh - 200px)'>
          {error && (
            <Alert status='error' mb={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}

          {/* Sorting and Filtering Controls */}
          <HStack justifyContent='space-between' mb={4}>
            {/* Sorting Menu */}
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Sort By
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    setSortBy('route');
                    setOrder('asc');
                    setPage(1);
                  }}
                >
                  Route (A-Z)
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSortBy('route');
                    setOrder('desc');
                    setPage(1);
                  }}
                >
                  Route (Z-A)
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSortBy('timestamp');
                    setOrder('desc');
                    setPage(1);
                  }}
                >
                  Most Recent
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSortBy('timestamp');
                    setOrder('asc');
                    setPage(1);
                  }}
                >
                  Oldest
                </MenuItem>
              </MenuList>
            </Menu>

            {/* Filtering Menu */}
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Filter By Route
              </MenuButton>
              <MenuList minWidth='240px'>
                <CheckboxGroup
                  value={selectedRoutes}
                  onChange={(values) => {
                    setSelectedRoutes(values);
                    setPage(1); // Reset to first page when filter changes
                  }}
                >
                  <VStack alignItems='start' spacing={2} p={2}>
                    {allRoutes.map((route) => (
                      <Checkbox key={route} value={route}>
                        {routeMappings[route]?.name || route}
                      </Checkbox>
                    ))}
                  </VStack>
                </CheckboxGroup>
              </MenuList>
            </Menu>
          </HStack>

          {results && results.length > 0 ? (
            <Box pb={100}>
              <VStack spacing={4} align='stretch'pb={30}>
                {results.map((result) => {
                  const businessCount = Array.isArray(result.data)
                    ? result.data.length
                    : Object.keys(result.data).length;

                  const routeInfo = routeMappings[result.route] || {
                    name: `Route: ${result.route}`,
                    icon: null,
                  };

                  const resultTimestamp = new Date(result.timestamp);
                  const now = new Date();
                  const timeDifference = now - resultTimestamp;
                  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
                  const timeLeft = sevenDaysInMs - timeDifference;

                  let expiryText;
                  let bgColor;

                  if (timeLeft <= 0) {
                    expiryText = 'Expired';
                    bgColor = 'gray.500';
                  } else {
                    const daysLeft = Math.floor(
                      timeLeft / (24 * 60 * 60 * 1000)
                    );
                    const hoursLeft = Math.floor(
                      (timeLeft % (24 * 60 * 60 * 1000)) /
                        (60 * 60 * 1000)
                    );

                    if (daysLeft >= 3 && daysLeft <= 6) {
                      bgColor = 'green.500';
                    } else if (daysLeft >= 1 && daysLeft <= 2) {
                      bgColor = 'yellow.500';
                    } else if (daysLeft < 1) {
                      bgColor = 'red.500';
                    } else {
                      bgColor = 'gray.500';
                    }

                    if (daysLeft > 0) {
                      expiryText = `${daysLeft} day${
                        daysLeft > 1 ? 's' : ''
                      } left`;
                    } else {
                      expiryText = `${hoursLeft} hour${
                        hoursLeft > 1 ? 's' : ''
                      } left`;
                    }
                  }

                  return (
                    <Box
                      key={result.id}
                      p={4}
                      borderWidth='1px'
                      borderRadius='lg'
                      bg='gray.50'
                      boxShadow='sm'
                    >
                      <HStack
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <Box>
                          <HStack alignItems='center'>
                            {routeInfo.icon && (
                              <Image
                                src={routeInfo.icon}
                                boxSize='40px'
                                alt='Icon'
                                mr={2}
                              />
                            )}
                            <VStack alignItems='flex-start' spacing={0}>
                              <Text fontSize='lg' fontWeight='bold'>
                                {routeInfo.name || `Route: ${result.route}`}
                              </Text>
                              <Text fontSize='sm' color='gray.600'>
                                Timestamp:{' '}
                                {new Date(result.timestamp).toLocaleString()}
                              </Text>
                            </VStack>
                          </HStack>
                          <Text mt={2}>
                            Found <strong>{businessCount}</strong> businesses.
                          </Text>
                        </Box>

                        <HStack>
                          <Box
                            bg={bgColor}
                            p={2}
                            borderRadius='md'
                            minW='100px'
                            textAlign='center'
                          >
                            <Text color='white' fontWeight='bold'>
                              {expiryText}
                            </Text>
                          </Box>

                          <Menu>
                            <MenuButton
                              as={Button}
                              rightIcon={<ChevronDownIcon />}
                              size='md'
                              colorScheme='blue'
                              aria-label='Download options'
                            >
                              Download
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                onClick={() =>
                                  handleDownload(result, 'json')
                                }
                              >
                                JSON
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleDownload(result, 'csv')
                                }
                              >
                                CSV
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleDownload(result, 'excel')
                                }
                              >
                                Excel
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </HStack>
                    </Box>
                  );
                })}
              </VStack>

              {/* Pagination Controls */}
              <HStack justifyContent='center'>
                <Button
                  onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                  disabled={page === 1}
                >
                  Previous
                </Button>
                <Text>
                  Page {page} of {totalPages}
                </Text>
                <Button
                  onClick={() =>
                    setPage((prev) => Math.min(prev + 1, totalPages))
                  }
                  disabled={page === totalPages}
                >
                  Next
                </Button>
              </HStack>
            </Box>
          ) : (
            <Text>No results to display.</Text>
          )}
        </Box>
      ) : (
        <Box mt={6} p={6} borderWidth='1px' borderRadius='md' bg='white'>
          <Text textAlign='center'>Please log in to view your results.</Text>
        </Box>
      )}
    </Box>
  );
};

export default Results;
