import React, { useState, useEffect } from 'react';
import {
  Heading,
  IconButton,
  Image,
  Flex,
  Spacer,
  Box,
  Textarea,
  Text,
  Wrap,
  Tag,
  WrapItem,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import website from '../../../../../assets/website.png';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../../utils/AuthContext';

const WebsiteEmail = () => {
  const [urls, setUrls] = useState('');
  const [uniqueDomains, setUniqueDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const navigate = useNavigate();
  const { frontendApiKey, userPlan } = useAuth(); // Get userPlan from AuthContext

  const isPaidUser = userPlan === 'paid'; // Check if the user is on a paid plan

  useEffect(() => {
    const preprocessInput = (input) => {
      const urlPattern = /(https?:\/\/[^\s]+|www\.[^\s]+|\S+\.\S+)/gi;
      const matches = input.match(urlPattern) || [];

      const domains = matches
        .map((url) => {
          try {
            let fullUrl = url;
            if (!/^https?:\/\//i.test(fullUrl)) {
              fullUrl = 'http://' + fullUrl;
            }
            let hostname = new URL(fullUrl).hostname;
            hostname = hostname.replace(/^www\./i, '');
            return getRootDomain(hostname);
          } catch (e) {
            return null;
          }
        })
        .filter(Boolean);

      return Array.from(new Set(domains));
    };

    const getRootDomain = (hostname) => {
      const parts = hostname.split('.').filter(Boolean);
      if (parts.length < 2) {
        return null;
      }
      const tlds = [
        'co.uk', 'org.uk', 'gov.uk', 'ac.uk', 'co.jp', 'com.au',
        'net.au', 'org.au', 'co.nz', 'com.sg', 'com.my', 'co.in',
        'co.id', 'com.br', 'com.mx', 'com.tr'
      ];
      const lastTwoParts = parts.slice(-2).join('.');
      const lastThreeParts = parts.slice(-3).join('.');

      if (tlds.includes(lastTwoParts)) {
        return parts.slice(-3).join('.');
      } else if (tlds.includes(lastThreeParts)) {
        return parts.slice(-3).join('.');
      } else {
        return parts.slice(-2).join('.');
      }
    };

    const preprocessedDomains = preprocessInput(urls);
    setUniqueDomains(preprocessedDomains);
  }, [urls]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);
    try {
      console.log('Request body:', {
        websites: uniqueDomains,
        flatten: false,
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/internal/website/email-socials`,
        {
          websites: uniqueDomains,
          flatten: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': frontendApiKey,
          },
        }
      );

      setResponseData((prevData) => [
        ...prevData,
        { domains: uniqueDomains, data: response.data },
      ]);
    } catch (error) {
      console.error('Error making the request:', error.message);
      if (error.response) {
        console.error('Status code:', error.response.status);
        console.error('Response body:', error.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (dataToDownload, index, format) => {
    // Generate a timestamp in the format YYYYMMDD_HHMMSS
    const now = new Date();
    const timestamp = now.getFullYear().toString() +
      ('0' + (now.getMonth() + 1)).slice(-2) +
      ('0' + now.getDate()).slice(-2) + '_' +
      ('0' + now.getHours()).slice(-2) +
      ('0' + now.getMinutes()).slice(-2) +
      ('0' + now.getSeconds()).slice(-2);

    let blob;
    let fileName = `website_email_social_${timestamp}`;

    if (format === 'json') {
      blob = new Blob([JSON.stringify(dataToDownload, null, 2)], {
        type: 'application/json',
      });
      fileName += '.json';
    } else if (format === 'csv') {
      const csv = jsonToCSV(dataToDownload);
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      fileName += '.csv';
    } else if (format === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(restructureData(dataToDownload));
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      fileName += '.xlsx';
    }

    saveAs(blob, fileName);
  };

  const jsonToCSV = (data) => {
    if (!data || !data.length) {
      return '';
    }

    const restructuredData = restructureData(data);
    const headers = Object.keys(restructuredData[0]);

    const csvRows = [
      headers.join(','),
      ...restructuredData.map((row) =>
        headers
          .map((fieldName) => {
            const value =
              row[fieldName] !== null && row[fieldName] !== undefined
                ? row[fieldName]
                : '';
            const escaped = ('' + value).replace(/"/g, '""');
            return `"${escaped}"`;
          })
          .join(',')
      ),
    ];

    return csvRows.join('\n');
  };

  const restructureData = (data) => {
    let maxEmails = 0;
    let maxPhones = 0;

    // Find the maximum number of emails and phones
    data.forEach((item) => {
      const emailCount = Object.keys(item).filter((key) => key.startsWith('email_')).length;
      const phoneCount = Object.keys(item).filter((key) => key.startsWith('phone_')).length;
      maxEmails = Math.max(maxEmails, emailCount);
      maxPhones = Math.max(maxPhones, phoneCount);
    });

    return data.map((item) => {
      const restructured = { ...item };

      // Remove existing email and phone fields
      Object.keys(item).forEach((key) => {
        if (key.startsWith('email_') || key.startsWith('phone_')) {
          delete restructured[key];
        }
      });

      // Add individual email columns
      for (let i = 1; i <= maxEmails; i++) {
        restructured[`email_${i}`] = item[`email_${i}`] || '';
      }

      // Add individual phone columns
      for (let i = 1; i <= maxPhones; i++) {
        restructured[`phone_${i}`] = item[`phone_${i}`] || '';
      }

      return restructured;
    });
  };

  // Define the email regex pattern
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

  // Function to count emails in the data
  const countEmails = (data) => {
    if (!data) return 0;
    // Convert data to string
    const jsonString = JSON.stringify(data);
    // Match emails using regex
    const matches = jsonString.match(emailRegex);
    // Return the count
    return matches ? matches.length : 0;
  };

  // Determine if the button should be disabled and why
  const isDisabled =
    !isPaidUser || uniqueDomains.length === 0 || uniqueDomains.length > 100;

  let disableReason = '';
  if (uniqueDomains.length === 0) {
    disableReason = 'Please add a website URL';
  } else if (!isPaidUser) {
    disableReason = 'You must be on a paid plan to use this feature';
  } else if (uniqueDomains.length > 100) {
    disableReason = 'You have exceeded the maximum of 100 websites';
  }

  return (
    <Box w="850px" mx="auto" mt={10} mb={100}>
      <Heading mb={4}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={handleBackClick}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            _hover={{ transform: 'translateX(-4px)' }}
            fontSize="2xl"
          />
          <Image src={website} alt="Website logo" boxSize="40px" mr={4} />
          Website Email Search Data
          <Spacer />
        </Flex>
      </Heading>

      <Box px={['10px', null, null, '0']}>
        <Textarea
          placeholder="Enter website URLs (any format, duplicates will be removed)"
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
          mb={4}
          bg="gray.100"
          h="300px"
          borderRadius="xl"
        />
        <Wrap spacing={2}>
          {uniqueDomains.map((domain, index) => (
            <WrapItem key={index}>
              <Tag size="md" variant="solid" colorScheme="blue">
                {domain}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
        <Text mt={4} fontFamily="Poppins" fontSize={20} mb={4}>
          You have entered <strong>{uniqueDomains.length}</strong> unique websites.
        </Text>
        {uniqueDomains.length > 100 && (
          <Text color="red.500" mb={4}>
            You have exceeded the maximum of 100 websites. Please remove some URLs.
          </Text>
        )}
        <Tooltip
          label={disableReason}
          isDisabled={!isDisabled}
          shouldWrapChildren
        >
          <Button
            colorScheme="blue"
            size="lg"
            isDisabled={isDisabled}
            onClick={handleSearchClick}
            isLoading={isLoading}
          >
            Search
          </Button>
        </Tooltip>

        {responseData.length > 0 &&
          responseData.map((responseEntry, index) => {
            const emailCount = countEmails(responseEntry.data);
            const websiteCount = responseEntry.domains.length;

            return (
              <Box
                key={index}
                mt={6}
                p={4}
                borderWidth="1px"
                borderRadius="md"
                bg="gray.100"
              >
                <Flex justify="space-between" align="center">
                  <Text>
                    You found <strong>{emailCount}</strong> emails for{' '}
                    <strong>{websiteCount}</strong> websites.
                  </Text>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      size="md"
                      colorScheme="blue"
                    >
                      Download
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'json')}>
                        JSON
                      </MenuItem>
                      <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'csv')}>
                        CSV
                      </MenuItem>
                      <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'excel')}>
                        Excel
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default WebsiteEmail;
