import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Image,
  Center,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Spinner,
} from '@chakra-ui/react';
import { useAuth } from '../../utils/AuthContext';
import googleIcon from '../../../assets/google.png';

const SignupMain = () => {
  const navigate = useNavigate();
  const { signup, googleSignIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await signup(email, password);
      setLoading(false);
      navigate('/verify');
    } catch (error) {
      console.error('Error creating user:', error);
      setError(`Signup.js:47 ${error.message}`);
      setLoading(false);
    }
  };

  const handleGoogleSignup = async () => {
    setLoading(true);

    try {
      await googleSignIn();
      setLoading(false);
      navigate('/data');
    } catch (error) {
      console.error('Error creating user:', error);
      setError(`Signup.js:47 ${error.message}`);
      setLoading(false);
    }
  };

  const isEmailValid = email.trim() !== '';
  const isPasswordValid = password.trim() !== '';

  if (loading) {
    return (
      <Center height="100vh" flexDirection="column">
        <Spinner size="xl" />
        <Text mt={4}>Please wait while we sign you up...</Text>
      </Center>
    );
  }

  return (
    <Box maxWidth="400px" margin="auto" pt={20} pb={60}>
      <VStack spacing={4}>
        <Text fontSize="4xl" fontWeight="bold">
          Sign Up
        </Text>
        {error && (
          <Alert status="error" borderRadius={10}>
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        <form onSubmit={handleSignup}>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              bg="gray.300"
              _hover={{ bg: 'gray.300' }}
              _focus={{ boxShadow: 'none', borderColor: 'gray.300' }}
              borderRadius={20}
              py={6}
              px={4}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              bg="gray.300"
              _hover={{ bg: 'gray.300' }}
              _focus={{ boxShadow: 'none', borderColor: 'gray.300' }}
              borderRadius={20}
              py={6}
              px={4}
            />
          </FormControl>
          <Text mt={4} fontSize="sm">
            By signing up, you agree to the{' '}
            <Link to="/privacy-policy" style={{ color: '#3182ce' }}>
              Privacy Policy
            </Link>{' '}
            and{' '}
            <Link to="/terms-of-service" style={{ color: '#3182ce' }}>
              Terms of Service
            </Link>.
          </Text>
          <Button
            type="submit"
            colorScheme="white"
            backgroundColor="white"
            color="#222222"
            fontFamily="Poppins"
            borderWidth={3}
            borderColor="#222222"
            borderRightWidth={6}
            borderBottomWidth={6}
            borderRadius={10}
            fontSize={18}
            py={6}
            px={8}
            mt={6}
            _hover={{ bg: 'gray.400' }}
            w={400}
            isDisabled={!isEmailValid || !isPasswordValid}
          >
            Sign Up
          </Button>
        </form>
        <Text mt={-2} fontSize="sm">
          Already Have an account?{' '}
          <Link to="/login" style={{ color: '#3182ce' }}>
            Login
          </Link>
        </Text>
        <Center>
          <Button
            onClick={handleGoogleSignup}
            leftIcon={<Image src={googleIcon} alt="Google" boxSize={6} />}
            colorScheme="white"
            backgroundColor="white"
            color="#222222"
            fontFamily="Poppins"
            borderWidth={3}
            borderColor="#222222"
            borderRightWidth={6}
            borderBottomWidth={6}
            borderRadius={10}
            fontSize={18}
            py={6}
            px={8}
            w={400}
            mt={4}
            _hover={{ bg: 'gray.400' }}
          >
            Continue With Google
          </Button>
        </Center>
      </VStack>
    </Box>
  );
};

export default SignupMain;
