// Filter.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Input,
  Flex,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Button,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useAuth } from "../../../../utils/AuthContext";
import Downloads from "./Downloads";

const parseGoogleMapsUrl = (url) => {
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+),(\d+)z/;
  const match = url.match(regex);
  if (match) {
    const [, lat, lng, zoom] = match;
    return { lat, lng, zoom };
  }
  return { lat: "", lng: "", zoom: "" };
};

const Filter = ({ savedUrls }) => {
  const [form, setForm] = useState({
    keyword: "",
    language: "en",
    amount: 500,
    country: "",
  });
  const [creditsRequired, setCreditsRequired] = useState(savedUrls.length * 50);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const { isAuthenticated, emailVerified, frontendApiKey } = useAuth();

  useEffect(() => {
    setCreditsRequired(savedUrls.length * 50);
  }, [savedUrls]);

  const handleChange = (field) => (e) => {
    const value = field === "amount" ? parseInt(e) : e.target.value;
    setForm((prev) => ({ ...prev, [field]: value }));
  };

  const handleSearch = async () => {
    if (!frontendApiKey) {
      console.error("Frontend API key not available. Please log in.");
      return;
    }

    if (!form.keyword.trim()) {
      console.error("Please enter a keyword to search.");
      return;
    }

    if (savedUrls.length === 0) {
      console.error("Please save a maps URL.");
      return;
    }

    setIsLoading(true);

    const searchPayload = savedUrls.map((url) => {
      const { lat, lng, zoom } = parseGoogleMapsUrl(url);
      return {
        query: form.keyword,
        limit: form.amount.toString(),
        country: form.country.toLowerCase(),
        lang: form.language,
        lat,
        lng,
        offset: "0",
        zoom,
        flatten: true,
      };
    });

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      const response = await fetch(`${apiBaseUrl}/internal/maps/search-bulk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": frontendApiKey,
        },
        body: JSON.stringify(searchPayload),
      });

      if (response.status === 405) {
        window.location.href = "/pricing?upgrade=true";
        return;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      const timestamp = new Date().toISOString();
      setSearchResults((prev) => [
        ...prev,
        { 
          id: prev.length + 1, 
          timestamp, 
          keyword: form.keyword, 
          data: result 
        },
      ]);
      
      console.log("Search successful:", result);
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const isSearchDisabled =
    !isAuthenticated ||
    !emailVerified ||
    !frontendApiKey ||
    isLoading ||
    !form.keyword.trim() ||
    savedUrls.length === 0;

  const getTooltipMessage = () => {
    if (!isAuthenticated) return "You must be logged in to search!";
    if (!emailVerified) return "Please verify your email to search!";
    if (!form.keyword.trim()) return "You must enter a keyword to search!";
    if (savedUrls.length === 0) return "Please save a maps URL.";
    if (!frontendApiKey) return "API key not available. Please try again later.";
    return "";
  };

  const tooltipMessage = isSearchDisabled ? getTooltipMessage() : "";

  return (
    <Box>
      <Text fontWeight="bold" fontFamily="Poppins" fontSize={20}>
        Keywords:
      </Text>
      <Input
        value={form.keyword}
        onChange={handleChange("keyword")}
        placeholder="Enter a keyword"
        fontFamily="Poppins"
        borderRadius="10"
        bg="gray.100"
      />

      <Flex mt={4} justifyContent="space-between">
        <Box width="30%">
          <Text fontSize="sm" fontFamily="Poppins" fontWeight="bold">
            Language:
          </Text>
          <Select
            value={form.language}
            onChange={handleChange("language")}
            fontFamily="Poppins"
            bg="gray.100"
            borderRadius={10}
          >
            <option value="en">English</option>
            <option value="es">Spanish</option>
            <option value="fr">French</option>
            <option value="de">German</option>
          </Select>
        </Box>

        <Box width="30%">
          <Text fontSize="sm" fontFamily="Poppins" fontWeight="bold">
            Amount:
          </Text>
          <NumberInput
            value={form.amount}
            onChange={handleChange("amount")}
            min={1}
            max={500}
            fontFamily="Poppins"
          >
            <NumberInputField bg="gray.100" borderRadius={10} />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>

        <Box width="30%">
          <Text fontSize="sm" fontFamily="Poppins" fontWeight="bold">
            Country:
          </Text>
          <Select
            value={form.country}
            onChange={handleChange("country")}
            fontFamily="Poppins"
            bg="gray.100"
            borderRadius={10}
          >
            <option value="">None</option>
            <option value="US">United States</option>
            <option value="CA">Canada</option>
            <option value="UK">United Kingdom</option>
            <option value="AU">Australia</option>
          </Select>
        </Box>
      </Flex>

      <Text mt={4} fontSize="xl">
        You are about to scrape <strong>{savedUrls.length}</strong> URLs for{" "}
        <strong>{creditsRequired}</strong> Credits
      </Text>

      <Tooltip label={tooltipMessage} isDisabled={!isSearchDisabled} hasArrow>
        <Box display="inline-block">
          <Button
            mt={4}
            colorScheme="blue"
            size="lg"
            onClick={handleSearch}
            isDisabled={isSearchDisabled}
            leftIcon={isLoading && <Spinner size="sm" />}
          >
            {isLoading ? "Searching..." : "Search Maps"}
          </Button>
        </Box>
      </Tooltip>

      <Downloads searchResults={searchResults} />
    </Box>
  );
};

export default Filter;
