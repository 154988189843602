// src/utils/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { 
  onAuthStateChanged, 
  signInWithPopup, 
  GoogleAuthProvider, 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';
import { auth, db } from '../../Firebase';
import { doc, getDoc } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userPlan, setUserPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [frontendApiKey, setFrontendApiKey] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);

  const googleProvider = new GoogleAuthProvider();

  const googleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      // Optionally, send email verification
      if (!auth.currentUser.emailVerified) {
        await sendEmailVerification(auth.currentUser);
      }
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw error;
    }
  };

  const signup = async (email, password) => {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    await sendEmailVerification(userCredential.user);
    return userCredential;
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const fetchFrontendApiKey = async (uid) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FETCH_URL}/get-frontend-api-key/${uid}`);
      if (!response.ok) {
        throw new Error('Failed to fetch API key');
      }
      const data = await response.json();
      setFrontendApiKey(data.frontendApiKey);
      // console.log(`Frontend API Key for UID ${uid}:`, data.frontendApiKey);
    } catch (error) {
      console.error('Error fetching API Key:', error.message);
      setFrontendApiKey(null);
    }
  };

  const fetchUserPlan = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', uid));
      
      if (userDoc.exists()) {
        const data = userDoc.data();
        if (data.status && data.status.toLowerCase() === 'active') {
          setUserPlan('paid');
        } else {
          setUserPlan('free');
        }
      } else {
        setUserPlan('free');
      }
    } catch (error) {
      setUserPlan('free');
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        // console.log('User UID:', currentUser.uid);
        setEmailVerified(currentUser.emailVerified);
        await fetchFrontendApiKey(currentUser.uid);
        await fetchUserPlan(currentUser.uid);
      } else {
        setFrontendApiKey(null);
        setUserPlan(null);
        setEmailVerified(false); 
      }
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    loading,
    isAuthenticated: !!user,
    emailVerified,
    frontendApiKey,
    googleSignIn,
    userPlan,
    signup,
    login, // Added login
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
