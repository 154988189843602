import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAmZm4yVTHJ8ef-Cfz2hSytcIbywKh_WLo",
    authDomain: "scrapetable-v5.firebaseapp.com",
    projectId: "scrapetable-v5",
    storageBucket: "scrapetable-v5",
    messagingSenderId: "726427718368",
    appId: "1:726427718368:web:503c2cfe9acc971dc93685",
    measurementId: "G-DS10E0HGT3" 
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export { app, auth, db, storage, functions, analytics };
