import React from 'react';
import { Box, Flex, Image, IconButton, VStack, Tooltip, Text, Button, Avatar } from '@chakra-ui/react';
import { NavLink, Outlet, Link as RouterLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.png';
import { 
  TbHome, 
  TbChartPie, 
  // TbApi, 
  // TbRss 
} from 'react-icons/tb';
import { useAuth } from '../utils/AuthContext';

const navItems = [
  { to: "dashboard", label: "Dashboard", icon: TbHome },
  { to: "results", label: "Results", icon: TbChartPie },
  // { to: "api", label: "API Builder", icon: TbApi },
  // { to: "feeds", label: "Feeds", icon: TbRss },
];

const NavButton = ({ to, label, icon: Icon }) => (
  <Tooltip label={label} placement="right">
    <NavLink to={to}>
      {({ isActive }) => (
        <IconButton
          aria-label={label}
          icon={<Icon size={32} />}
          variant={isActive ? "solid" : "ghost"}
          bg={isActive ? "blue.500" : "transparent"}
          color={isActive ? "white" : "gray.500"}
          _hover={{
            bg: isActive ? "blue.600" : "gray.100",
          }}
          size="lg"
        />
      )}
    </NavLink>
  </Tooltip>
);

const StudioEntry = () => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleAvatarClick = () => {
    navigate('/dashboard/account');
  };

  return (
    <Flex>
      <Box width="100px" h="100vh" borderRightWidth="2px">
        <Flex direction="column" h="full" align="center">
          <Flex h="80px" justify="center" align="center" borderBottomWidth="2px" width="full">
            <Tooltip label="Home" placement="right">
              <RouterLink to="/">
                <Image src={logo} alt="Logo" boxSize="50px" />
              </RouterLink>
            </Tooltip>
          </Flex>
          <VStack spacing={4} mt={4}>
            {navItems.map((item) => (
              <NavButton key={item.to} {...item} />
            ))}
          </VStack>
        </Flex>
      </Box>
      <Flex direction="column" flex="1">
        <Box h="80px" borderBottomWidth="2px" p={4}>
          <Flex justify="flex-end" align="center" h="full">
            {isAuthenticated ? (
              <Flex align="center" cursor="pointer" onClick={handleAvatarClick}     transition="transform 0.2s ease-in-out"
              _hover={{
                transform: "scale(1.05)",
              }}>
                <Avatar size="md" name={user.displayName || user.email} src={user.photoURL} mr={2} />
                <Box>
                  <Text fontSize="md" fontWeight="bold">{user.displayName || 'User'}</Text>
                  <Text fontSize="sm" color="gray.600">{user.email}</Text>
                </Box>
              </Flex>
            ) : (
              <Flex gap={4}>
                <Button
                  as={RouterLink}
                  to="/login"
                  w="120px"
                  colorScheme="blue"
                >
                  Login
                </Button>
                <Button
                  as={RouterLink}
                  to="/signup"
                  w="120px"
                  colorScheme="white"
                  color="#222222"
                  _hover={{ bg: "gray.300" }}
                >
                  Sign Up
                </Button>
              </Flex>
            )}
          </Flex>
        </Box>
        <Box flex="1" p={6} overflowY="auto">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  );
};

export default StudioEntry;