import React from "react";
import { Box, VStack, HStack, Text, Button, useColorModeValue } from "@chakra-ui/react";

const PricingEnterprise = () => {
  const boxBg = useColorModeValue('gray.100', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');
  const textColor = useColorModeValue('gray.600', 'gray.200');

  return (
    <Box bg={boxBg} py={10} maxW="1200px" mx="auto" px={8} borderRadius="md" boxShadow="xl">
      <HStack spacing={8} align="center" justify="center">
        <VStack align="start" spacing={2} flex={1}>
          <Text fontSize="4xl" fontWeight="bold" color={headingColor}>
            Need more usage?
          </Text>
          <Text fontSize="lg" color={textColor}>
            Let us know and we'll create a custom plan for your business.
          </Text>
        </VStack>
        <Button
          colorScheme="blue"
          as="a"
          href="mailto:contact@scrapetable.com"
          size="lg"
        >
          Contact Us
        </Button>
      </HStack>
    </Box>
  );
};

export default PricingEnterprise;