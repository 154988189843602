import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import HomeHero from './HomeHero';
import HomeUseCases from './HomeUseCases';
import HomeAPI from './HomeAPI';
import HomeScroll from './HomeScroll';

function Home() {
  return (
    <>
      <Header />
      <HomeHero />
      <HomeScroll/>
      <HomeUseCases/>
      <HomeAPI/>
      <Footer/>
    </>
  );
}

export default Home;
