import React, { useEffect, useState } from 'react';
import { auth } from '../../../Firebase';
import { onAuthStateChanged, sendEmailVerification } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Center, Spinner, Box, Text, Button, Link, Heading, useToast } from '@chakra-ui/react';

const VerifyMain = () => {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [checkingVerification, setCheckingVerification] = useState(false);
  const toast = useToast();
  const functions = getFunctions();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setEmail(user.email);
        if (user.emailVerified) {
          const updateStatus = httpsCallable(functions, 'updateEmailVerificationStatus');
          await updateStatus();
          window.location.href = '/data';
        } else {
          setLoading(false);
        }
      } else {
        window.location.href = '/login';
      }
    });

    return () => unsubscribe();
  }, [functions]);

  const handleResendVerificationEmail = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        toast({
          title: 'Verification Email Sent',
          description: `Please check your inbox at ${email}.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Error Sending Verification Email',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleCheckVerificationStatus = async () => {
    if (auth.currentUser) {
      setCheckingVerification(true);
      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        const updateStatus = httpsCallable(functions, 'updateEmailVerificationStatus');
        await updateStatus();
        window.location.href = '/data';
      } else {
        setCheckingVerification(false);
        toast({
          title: 'Email Not Verified',
          description: 'Please check your inbox.',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  if (loading) {
    return (
      <Center height="80vh" flexDirection="column">
        <Spinner size="xl" />
        <Text mt={4}>Checking verification status...</Text>
      </Center>
    );
  }

  return (
    <Center height="80vh" flexDirection="column">
      <Box textAlign="center">
        <Heading fontSize="2xl" mb={4}>
          Please Verify Your Email to Continue
        </Heading>
        <Button 
          onClick={handleCheckVerificationStatus} 
          colorScheme="blue" 
          mb={4} 
          borderWidth={3} 
          borderColor={'#222222'} 
          borderRightWidth={6} 
          borderBottomWidth={6} 
          borderRadius={10}
          isDisabled={checkingVerification}
        >
          {checkingVerification ? <Spinner size="sm" mr={2} /> : null}
          Check Verification Status
        </Button>
        <Text>
          Didn't receive the verification email?{' '}
        </Text>
        <Link onClick={handleResendVerificationEmail} color="blue.500" cursor="pointer">
          Resend Verification Email <br /> to {email}
        </Link>
      </Box>
    </Center>
  );
};

export default VerifyMain;
