import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  Button,
  Tooltip,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../utils/AuthContext"; // Adjust the path as needed
import { HamburgerIcon } from "@chakra-ui/icons";
import logo from "../../assets/logo.png";

const Header = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => setShowTooltip(true), 3000);
    } else {
      setShowTooltip(false);
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  const navItems = [
    { name: "Sources", to: "/data", isExternal: false, borderColor: "black" },
    { name: "Pricing", to: "/pricing", isExternal: false, borderColor: "black" },
    { name: "Studio", to: "/studio", isExternal: false, borderColor: "black" },
    // { name: "Blog", to: "/blog", isExternal: false, borderColor: "black" },
  ];

  // Reusable NavLink component
  const NavLink = ({ name, to, isExternal, borderColor }) => (
    <Link
      as={isExternal ? "a" : RouterLink}
      href={isExternal ? to : undefined}
      to={!isExternal ? to : undefined}
      isExternal={isExternal}
      fontWeight="bold"
      fontSize="18px"
      position="relative"
      textDecoration="none"
      _after={
        !isMobile
          ? {
              content: '""',
              position: "absolute",
              width: "0%",
              height: "3px",
              bottom: "-2px",
              left: "0",
              bg: borderColor,
              transition: "width 0.3s ease-in-out",
              borderRadius: "full"
            }
          : {}
      }
      _hover={
        !isMobile
          ? {
              _after: {
                width: "100%",
              },
            }
          : {}
      }
    >
      {name}
    </Link>
  );

  // Generate NavLinks using the navItems array
  const NavLinks = () => (
    <>
      {navItems.map((item) => (
        <NavLink
          key={item.name}
          name={item.name}
          to={item.to}
          isExternal={item.isExternal}
          borderColor={item.borderColor}
        />
      ))}
    </>
  );

  // AuthButtons component
  const AuthButtons = () => (
    user ? (
      <Button
        as={RouterLink}
        to="/dashboard"
        colorScheme="white"
        color="#222222"
        _hover={{ bg: "gray.300" }}
      >
        Dashboard
      </Button>
    ) : (
      <Flex gap={4}>
        <Button
          as={RouterLink}
          to="/login"
          w="120px"
          colorScheme="blue"
        >
          Login
        </Button>
        <Button
          as={RouterLink}
          to="/signup"
          w="120px"
          colorScheme="white"
          color="#222222"
          _hover={{ bg: "gray.300" }}
        >
          Sign Up
        </Button>
      </Flex>
    )
  );

  return (
    <Box py={6} px={4}>
      <Flex maxW="1200px" mx="auto" alignItems="center" justifyContent="space-between">
        <RouterLink to="/">
          <Flex alignItems="center">
            <Tooltip label="how the turntables" isOpen={showTooltip} borderRadius={10}>
              <Image
                src={logo}
                alt="Logo"
                mr={2}
                boxSize="3rem"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "rotate(180deg)" }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              />
            </Tooltip>
            <Heading as="h1" fontSize="3xl" fontFamily="Poppins">
              Scrape Table
            </Heading>
          </Flex>
        </RouterLink>

        {!isMobile && (
          <Flex alignItems="center" justifyContent="center" flex="1" gap={8}>
            <NavLinks />
          </Flex>
        )}

        {!isMobile && (
          <Flex alignItems="center">
            <AuthButtons />
          </Flex>
        )}

        {isMobile && (
          <IconButton
            aria-label="Open menu"
            icon={<HamburgerIcon />}
            onClick={onOpen}
          />
        )}
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <VStack spacing={4} align="stretch">
                <NavLinks />
                <AuthButtons />
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Header;
