import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import BlogPostMain from './BlogPostMain';

function BlogPost() {
  return (
    <>
      <Header />
      <BlogPostMain/>
      <Footer/>
    </>
  );
}

export default BlogPost;
