import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import VerifyMain from './VerifyMain';

function Terms() {
  return (
    <>
      <Header />
      <VerifyMain />
      <Footer/>
    </>
  );
}

export default Terms;
