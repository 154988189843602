import React, { useState, useEffect, useRef, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Box, Flex, Spinner, useColorModeValue } from "@chakra-ui/react";
import Sidebar from "./Sidebar";
import Credits from "./Credits";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const GetMapsData = () => {
  const [userLocation, setUserLocation] = useState({
    lat: 40.7128,
    lng: -74.0059,
  });
  const [mapCenter, setMapCenter] = useState(userLocation);
  const [zoomLevel, setZoomLevel] = useState(14);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [sortOption, setSortOption] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [businesses, setBusinesses] = useState([]);

  const mapRef = useRef(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const onMapIdle = useCallback(() => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      setMapCenter({ lat: center.lat(), lng: center.lng() });
      setZoomLevel(mapRef.current.getZoom());
    }
  }, []);

  useEffect(() => {
    if (isLoaded && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          setUserLocation({ lat: latitude, lng: longitude });
          setMapCenter({ lat: latitude, lng: longitude });
          setIsMapLoading(false);
        },
        (error) => {
          console.error("Error getting user location:", error);
          setIsMapLoading(false);
        }
      );
    } else {
      setIsMapLoading(false);
    }
  }, [isLoaded]);

  const callGetMapsDataFree = useCallback(async (data) => {
    try {
      const response = await fetch(`${apiBaseUrl}/internal/get-maps-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setSearchResult(result);
      console.log("Data received successfully", result);
    } catch (error) {
      console.error("Error calling getMapsDataFree:", error);
    }
  }, []);

  const handleSearch = useCallback(async () => {
    if (searchTerm.trim() === "") return;

    setIsSearching(true);
    const geocoder = new window.google.maps.Geocoder();

    try {
      const results = await new Promise((resolve, reject) => {
        geocoder.geocode({ address: searchTerm }, (results, status) => {
          if (status === "OK" && results[0]) resolve(results);
          else reject(new Error("Geocoding failed"));
        });
      });

      const { lat, lng } = results[0].geometry.location;
      setUserLocation({ lat: lat(), lng: lng() });
      setMapCenter({ lat: lat(), lng: lng() });
      setZoomLevel(14);

      await callGetMapsDataFree({
        lat: lat(),
        lng: lng(),
        zoom: 14,
        keyword: searchTerm,
      });
    } catch (error) {
      console.error("Error during search:", error);
      await callGetMapsDataFree({
        lat: mapCenter.lat,
        lng: mapCenter.lng,
        zoom: zoomLevel,
        keyword: searchTerm,
      });
    } finally {
      setIsSearching(false);
    }
  }, [
    callGetMapsDataFree,
    mapCenter.lat,
    mapCenter.lng,
    searchTerm,
    zoomLevel,
  ]);

  const handleAddAll = useCallback((filteredResults) => {
    setBusinesses((prevBusinesses) => {
      const newBusinesses = [...prevBusinesses];
      filteredResults.forEach((business) => {
        if (!newBusinesses.some((b) => b.business_id === business.business_id)) {
          newBusinesses.push(business);
        }
      });
      return newBusinesses;
    });
  }, []);

  const handleAddBusiness = useCallback((business) => {
    setBusinesses((prevBusinesses) => {
      if (!prevBusinesses.some((b) => b.business_id === business.business_id)) {
        return [...prevBusinesses, business];
      }
      return prevBusinesses;
    });
  }, []);

  const textColor = useColorModeValue("gray.800", "white");

  return (
    <Box width="100vw" height="100vh" position="relative" backgroundColor="#FFFCF4">
      {isLoaded ? (
        isMapLoading ? (
          <Flex justify="center" align="center" height="100%">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <GoogleMap
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={mapCenter}
            zoom={zoomLevel}
            options={{
              mapTypeControl: false,
              fullscreenControl: false,
              streetViewControl: false,
            }}
            onIdle={onMapIdle}
            onLoad={(map) => {
              mapRef.current = map;
            }}
          >
            <Marker position={userLocation} />
            {searchResult.map((place) => (
              <Marker
                key={place.business_id || place.id}
                position={{ lat: place.latitude, lng: place.longitude }}
              />
            ))}
          </GoogleMap>
        )
      ) : (
        <Flex justify="center" align="center" height="100%">
          <Spinner size="xl" />
        </Flex>
      )}

      <Sidebar
        mapCenter={mapCenter}
        zoomLevel={zoomLevel}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        isSearching={isSearching}
        searchResult={searchResult}
        handleAddAll={handleAddAll}
        textColor={textColor}
        showTooltip={showTooltip}
        setShowTooltip={setShowTooltip}
        sortOption={sortOption}
        setSortOption={setSortOption}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        handleAddBusiness={handleAddBusiness}
      />

      <Credits businesses={businesses} setBusinesses={setBusinesses} />
    </Box>
  );
};

export default GetMapsData;
