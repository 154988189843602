import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import SignupMain from './SignUpMain';


function Login() {
  return (
    <>
      <Header />
      <SignupMain />
      <Footer/>
    </>
  );
}

export default Login;
