import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Flex,
  Text,
  Tooltip,
  Image,
  Heading,
  Input,
  Button,
  VStack,
  IconButton,
  Select,
  Center,
  useToast,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import logo from "../../../../../assets/logo.png";
import darkLogo from "../../../../../assets/muted.png";
import PlaceCard from "./PlaceCard";

const ITEMS_PER_PAGE = 20;

const tips = [
  "Try searching for a specific business type!",
  "Use the sort options to organize your results.",
  "Filter for businesses with or without websites.",
  "Click 'Add All' to include all search results.",
  "Zoom in or out to refine your search area.",
  "Explore different categories to find hidden gems.",
  "Check out the top-rated businesses first!",
  "Filter by open hours to see only currently open places.",
];

const Sidebar = ({
  mapCenter,
  zoomLevel,
  searchTerm,
  setSearchTerm,
  handleSearch,
  isSearching,
  searchResult,
  handleAddAll,
  textColor,
  showTooltip,
  setShowTooltip,
  sortOption,
  setSortOption,
  filterOption,
  setFilterOption,
  handleAddBusiness,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedResults, setDisplayedResults] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [currentTipIndex, setCurrentTipIndex] = useState(0);
  const [isTipVisible, setIsTipVisible] = useState(true);
  const toast = useToast();

  const sortedAndFilteredResults = useMemo(
    () =>
      searchResult
        .filter((place) => {
          if (filterOption === "hasWebsite") return place.website;
          if (filterOption === "noWebsite") return !place.website;
          return true;
        })
        .sort((a, b) => {
          if (sortOption === "ratingHighToLow") return b.rating - a.rating;
          if (sortOption === "ratingLowToHigh") return a.rating - b.rating;
          if (sortOption === "reviewsHighToLow") return b.review_count - a.review_count;
          if (sortOption === "reviewsLowToHigh") return a.review_count - b.review_count;
          return 0;
        }),
    [searchResult, sortOption, filterOption]
  );

  useEffect(() => {
    setDisplayedResults(sortedAndFilteredResults.slice(0, currentPage * ITEMS_PER_PAGE));
  }, [sortedAndFilteredResults, currentPage]);

  useEffect(() => {
    const changeTip = () => {
      setIsTipVisible(false);
      setTimeout(() => {
        setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
        setIsTipVisible(true);
      }, 1000);
    };

    const intervalId = setInterval(changeTip, 6000); // 5s visible + 1s fade out

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => {
        setShowTooltip(true);
      }, 3000);
    } else {
      setShowTooltip(false);
    }
    return () => clearTimeout(timer);
  }, [isHovered, setShowTooltip]);

  const handleScroll = useCallback((e) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, []);

  const handleNewSearch = () => {
    setCurrentPage(1);
    handleSearch();
  };

  const handleAddFilteredResults = () => {
    handleAddAll(sortedAndFilteredResults);
    toast({
      title: "Added All Businesses",
      description: `${sortedAndFilteredResults.length} businesses have been added.`,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "bottom",
      variant: "solid",
    });
  };

  const handleAddSingleBusiness = (business) => {
    handleAddBusiness(business);
    toast({
      title: "Business Added",
      description: `${business.name} has been added.`,
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom",
      variant: "solid",
    });
  };

  return (
    <Box
      position="absolute"
      top="15px"
      left="15px"
      bg="#FFFCF4"
      p={6}
      borderRadius="25px"
      boxShadow="md"
      textAlign="center"
      w="400px"
      height="calc(100vh - 30px)"
      display="flex"
      flexDirection="column"
    >
      <RouterLink to="/">
        <Flex alignItems="center" justifyContent="center" mb={4}>
          <Tooltip label="how the turntables" isOpen={showTooltip} borderRadius={10}>
            <Image
              src={logo}
              alt="Logo"
              mr={2}
              boxSize="3rem"
              transition="transform 0.3s ease-in-out"
              _hover={{
                transform: "rotate(180deg)",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
          </Tooltip>
          <Heading as="h1" fontSize="3xl" fontFamily="Poppins">
            Scrape Table
          </Heading>
        </Flex>
      </RouterLink>
      <Flex justifyContent="center" color="gray.500" fontSize="sm">
        <Text mr={2}>Lat: {mapCenter.lat.toFixed(4)}</Text>
        <Text mr={2}>Long: {mapCenter.lng.toFixed(4)}</Text>
        <Text>Zoom: {zoomLevel}</Text>
      </Flex>
      <Flex mt={4} alignItems="center">
        <Input
          placeholder="Search maps via keyword..."
          bg="gray.200"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleNewSearch();
            }
          }}
        />
        <IconButton
          icon={<SearchIcon />}
          onClick={handleNewSearch}
          ml={2}
          bg="gray.200"
          isLoading={isSearching}
          aria-label="Search"
        />
      </Flex>
      <Flex mt={4} justifyContent="space-between">
        <Select
          placeholder="Sort by (None)"
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          w="48%"
          fontSize="14px"
          bg="gray.200"
        >
          <option value="ratingHighToLow">Rating: High to Low</option>
          <option value="ratingLowToHigh">Rating: Low to High</option>
          <option value="reviewsHighToLow">Reviews: High to Low</option>
          <option value="reviewsLowToHigh">Reviews: Low to High</option>
        </Select>
        <Select
          placeholder="Filter by (None)"
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          w="48%"
          fontSize="14px"
          bg="gray.200"
        >
          <option value="hasWebsite">Has Website</option>
          <option value="noWebsite">No Website</option>
        </Select>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between" mt={4}>
        <Text fontSize="24px" fontWeight="bold">
          {sortedAndFilteredResults.length} Places Found
        </Text>
        <Button
          colorScheme="green"
          onClick={handleAddFilteredResults}
          borderWidth={3}
          borderColor="#222222"
          borderRightWidth={6}
          borderBottomWidth={6}
          borderRadius={10}
        >
          Add All
        </Button>
      </Flex>
      <Box
        mt={4}
        flex={1}
        overflowY="auto"
        overflowX="hidden"
        onScroll={handleScroll}
        css={{
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '24px',
          },
        }}
      >
        {displayedResults.length > 0 ? (
          <VStack spacing={4} align="stretch">
            {displayedResults.map((place) => (
              <PlaceCard
                key={place.business_id || place.id}
                place={place}
                textColor={textColor}
                handleAddBusiness={() => handleAddSingleBusiness(place)}
              />
            ))}
          </VStack>
        ) : (
          <Center flexDirection="column" height="100%">
            <Image src={darkLogo} alt="Dark Logo" boxSize="50px" mb={4} opacity={0.5}/>
            <Box
              height="3em"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Text
                fontSize="sm"
                color="gray.600"
                textAlign="center"
                opacity={isTipVisible ? 1 : 0}
                transition="opacity 1s ease-in-out"
              >
                {tips[currentTipIndex]}
              </Text>
            </Box>
          </Center>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;