import React, { useEffect, useState } from 'react';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

const BlogPostMain = () => {
  const { slug } = useParams(); // Use slug for lookup
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = 'https://stable-fish-86605475e6.strapiapp.com/api/articles';
  const API_KEY = 'f00fedfc06c541a9078c68d74388b5e4c6bfdf99856196c8687eeb92147eef907e8a05e41f73a301c97b2a179274d4928ea1556fd3e18d8f7d0c770fdad272c6d5336c73e0d074eb4362c47c92170483d8122dad3bc4e28d4cdca9a39a73d8c8c4253889e13f581b9ec0a4a984b4b4f0c4b402654ed846b6c1021026deb98408'; // Use your actual API key here

  useEffect(() => {
    const fetchArticle = async () => {
      console.log('Starting to fetch article with slug:', slug);

      try {
        const response = await fetch(API_URL, {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        });

        console.log('Response status:', response.status);

        if (!response.ok) {
          throw new Error('Failed to fetch article');
        }

        const data = await response.json();
        console.log('Fetched data:', data);

        const foundArticle = data.data.find((article) => article.slug === slug);
        console.log('Found article:', foundArticle);

        if (foundArticle) {
          setArticle(foundArticle);
        } else {
          console.log('Article not found for slug:', slug);
          setError('Article not found');
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching article:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) {
    return <Spinner size="xl"/>;
  }

  if (error) {
    return <Text color="red.500" h="100vh">{error}</Text>;
  }

  return (
    <Box p={4} h="100vh">
      <Text as="pre">{JSON.stringify(article, null, 2)}</Text>
    </Box>
  );
};

export default BlogPostMain;
