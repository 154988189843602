// src/components/pages/pricing-page/PricingMain.js

import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  SimpleGrid,
  Button,
  Icon,
  useColorModeValue,
  Progress,
  Tag,
  Spinner,
  HStack,
  Badge,
} from '@chakra-ui/react';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../utils/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../Firebase';
import { StarIcon } from '@chakra-ui/icons';
import pricingPlans from './plans.json'; // Adjust the path as needed

// **Imported motion from Framer Motion**
import { motion } from 'framer-motion';

// **Created motion components for Chakra UI components**
const MotionBox = motion(Box);
const MotionSimpleGrid = motion(SimpleGrid);

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    'pk_live_51MN3GdDWaiv6TkQpC4XCJYBBENWt71qHxbFg2euWBkKumpuDqfnsSiWUUsVDDyuwxwnvfu6PZvtv4xwu00mcqddg00LCjmIq1C'
);

const PricingMain = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [realTimeUserDetails, setRealTimeUserDetails] = useState(null);
  const [loading, setLoading] = useState({});
  const [isYearly, setIsYearly] = useState(true); // Set default to yearly

  // Move all useColorModeValue hooks to the top level
  const boxBg = useColorModeValue('gray.100', 'gray.700');
  const headingColor = useColorModeValue('gray.800', 'white');
  const buttonBg = useColorModeValue('blue.600', 'blue.400');
  const buttonColor = useColorModeValue('white', 'gray.800');
  const buttonHoverBg = useColorModeValue('blue.700', 'blue.500');
  const iconColor = useColorModeValue('green.500', 'green.300');
  const plansTextColor = useColorModeValue('gray.800', 'white'); // New variable for "Plans Built To Scale" text

  // Destructure the plans from the imported JSON
  const { yearly: plansYearly, monthly: plansMonthly } = pricingPlans;

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        const data = docSnapshot.data();
        setRealTimeUserDetails(data);
        if (data && data.customerId) {
          console.log('Customer ID on load:', data.customerId);
        } else {
          console.log('No customer ID found.');
        }

        // **Determine Billing Cycle Based on Current Plan**
        const userPriceIds = data.priceIds || [];

        // Check if any of the user's priceIds match yearly plans
        const isYearlyPlan = plansYearly.some(
          (plan) =>
            userPriceIds.includes(plan.unitPriceId) ||
            userPriceIds.includes(plan.monthlyPriceId)
        );

        // Check if any of the user's priceIds match monthly plans
        const isMonthlyPlan = plansMonthly.some(
          (plan) =>
            userPriceIds.includes(plan.unitPriceId) ||
            userPriceIds.includes(plan.monthlyPriceId)
        );

        if (isMonthlyPlan && !isYearlyPlan) {
          setIsYearly(false);
        } else if (isYearlyPlan && !isMonthlyPlan) {
          setIsYearly(true);
        } else if (isYearlyPlan && isMonthlyPlan) {
          // If both are true, prioritize yearly or handle accordingly
          // Here, we prioritize yearly
          setIsYearly(true);
        } else {
          // If no plan is found, default to yearly
          setIsYearly(true);
        }
      });

      return () => unsubscribe();
    } else {
      setRealTimeUserDetails(null); // Clear user details when not logged in
      setIsYearly(true); // Reset to default if user logs out
    }
  }, [user, plansYearly, plansMonthly]);

  const handleGetStarted = async (unitPriceId, monthlyPriceId, limit) => {
    if (!user) {
      navigate('/signup');
      return;
    }

    setLoading((prevLoading) => ({ ...prevLoading, [unitPriceId]: true }));

    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createP2Fix-createP2Fix');

    try {
      const { data } = await createCheckoutSession({
        unitPriceId,
        monthlyPriceId,
        limit,
        successUrl: `${window.location.origin}/data`,
        cancelUrl: `${window.location.origin}/pricing`,
        customerId: realTimeUserDetails?.customerId || null,
        isYearly,
      });
      console.log('Customer ID passed to function:', realTimeUserDetails?.customerId);
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    } catch (error) {
      console.error('Error creating checkout session:', error);
      // Optional: Display user-friendly error message here
    } finally {
      setLoading((prevLoading) => ({ ...prevLoading, [unitPriceId]: false }));
    }
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const plans = isYearly ? plansYearly : plansMonthly;

  // **Defined animation variants for the container and items**
  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2, // Adjust the delay between cards here
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
      },
    },
  };

  return (
    <Box py={50} maxW="1200px" mx="auto">
      <Heading as="h1" size="3xl" textAlign="center" mb={10}>
        Pricing Plans
      </Heading>

      {/* Button-based Toggle for Billing Cycle */}
      <Flex justify="center" align="center" mb={8}>
        <HStack
          bg="gray.50"
          p={2}
          borderRadius="full"
          borderWidth="3px"
          borderColor="#222222"
          borderBottomWidth={6}
          borderRightWidth={6}
        >
          <Button
            colorScheme={isYearly ? 'blue' : 'gray'}
            variant={isYearly ? 'solid' : 'outline'}
            onClick={() => setIsYearly(true)}
            borderRadius="full"
            mr={1}
            borderWidth="0px"
            position="relative"
          >
            Annual Billing
            <Badge
              colorScheme="blue"
              borderRadius="full"
              px={2}
              py={1}
              fontSize="xs"
              fontWeight="bold"
              position="absolute"
              top="35px"
            >
              2 MONTHS FREE
            </Badge>
          </Button>
          <Button
            colorScheme={!isYearly ? 'blue' : 'gray'}
            variant={!isYearly ? 'solid' : 'outline'}
            onClick={() => setIsYearly(false)}
            borderRadius="full"
            borderWidth="0px"
          >
            Monthly Billing
          </Button>
        </HStack>
      </Flex>

      {/* Conditional Rendering: Progress Bar or "Plans Built To Scale" Text */}
      <Box mb={8} textAlign="center" h="60px">
        {user && realTimeUserDetails ? (
          <Flex justify="center" align="center" direction="column">
            <Text mb={2} fontSize="20px" fontWeight="bold">
              {formatNumber(realTimeUserDetails.usage)} / {formatNumber(realTimeUserDetails.limit)}
            </Text>
            <Box
              width="50%"
              borderRadius="25px"
              borderWidth="4px"
              borderBottomWidth="8px"
              borderRightWidth="8px"
              borderColor="#222222"
              mx="auto"
            >
              <Progress
                value={(realTimeUserDetails.usage / realTimeUserDetails.limit) * 100}
                colorScheme={realTimeUserDetails.usage >= realTimeUserDetails.limit ? 'red' : 'blue'}
                borderRadius="25px"
                height="24px"
              />
            </Box>
          </Flex>
        ) : (
          <Text fontSize="3xl" color={plansTextColor}>
            Data for leads, sales and insights with just a few clicks
          </Text>
        )}
      </Box>

      {/* **Wrapped SimpleGrid with MotionSimpleGrid and applied container variants** */}
      <MotionSimpleGrid
        spacing={4}
        columns={{ base: 1, md: 4 }}
        mt={50}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {plans.map((plan) => {
          const isCurrentPlan =
            realTimeUserDetails?.priceIds?.includes(plan.unitPriceId) ||
            realTimeUserDetails?.priceIds?.includes(plan.monthlyPriceId);
          const showPriceSuffix = plan.name !== 'Free'; // Conditionally show '/year' or '/m'

          return (
            // **Wrapped each card with MotionBox and applied item variants**
            <MotionBox
              key={plan.name}
              bg={boxBg}
              p={8}
              rounded="md"
              boxShadow="lg"
              position="relative"
              variants={itemVariants}
            >
              <Flex justify="space-between" align="center" mb={7}>
                <Heading as="h2" fontSize="30px" color={headingColor}>
                  {plan.name}
                </Heading>
                {plan.name === 'Pro' && (
                  <Tag
                    size="md"
                    variant="solid"
                    bg="#f5be27"
                    color="#ffffff"
                    alignSelf="center"
                    display="flex"
                    alignItems="center"
                  >
                    <StarIcon color="white" mr={1} /> RECOMMENDED
                  </Tag>
                )}
              </Flex>
              <Flex align="baseline">
                <Text fontSize="4xl" fontWeight="bold">
                  {plan.price}
                </Text>
                {showPriceSuffix && (
                  <Text as="span" fontSize="xl" color="gray.500" ml={2}>
                    /{isYearly ? 'm' : 'm'}
                  </Text>
                )}
              </Flex>
              <Text fontSize="sm" color="gray.500" mb={4} h="20px">
                {plan.billedAt}
              </Text>
              <Button
                w="full"
                bg={isCurrentPlan ? 'gray.500' : buttonBg}
                color={isCurrentPlan ? 'white' : buttonColor}
                onClick={() => handleGetStarted(plan.unitPriceId, plan.monthlyPriceId, plan.limit)}
                isDisabled={isCurrentPlan || loading[plan.unitPriceId]}
                mb={8}
                _hover={{
                  bg: isCurrentPlan ? 'gray.500' : buttonHoverBg,
                }}
              >
                {loading[plan.unitPriceId] ? (
                  <Spinner size="sm" />
                ) : isCurrentPlan ? (
                  'Current Plan'
                ) : user ? (
                  'Get Started'
                ) : (
                  'Start for Free'
                )}
              </Button>
              <Stack spacing={4}>
                {plan.features.map((feature, index) => (
                  <Flex key={index} align="center">
                    <Icon
                      as={feature.includes('No support') ? FaTimes : FaCheck}
                      color={feature.includes('No support') ? 'red.500' : iconColor}
                      mr={2}
                    />
                    <Text>{feature}</Text>
                  </Flex>
                ))}
              </Stack>
            </MotionBox>
          );
        })}
      </MotionSimpleGrid>
    </Box>
  );
};

export default PricingMain;
