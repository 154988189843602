import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Heading,
  IconButton,
  Image,
  Flex,
  Spacer,
  Box,
  Textarea,
  Text,
  Wrap,
  Tag,
  WrapItem,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import linkedin from '../../../../../assets/linkedin.png';
import { useAuth } from '../../../../utils/AuthContext';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const LinkedinProfile = () => {
  const [urls, setUrls] = useState('');
  const [uniqueProfiles, setUniqueProfiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const navigate = useNavigate();
  const { frontendApiKey, userPlan } = useAuth();

  const isPaidUser = userPlan === 'paid';

  useEffect(() => {
    const preprocessInput = (input) => {
      const linkedinProfileRegex = /(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([a-zA-Z0-9-]+)/gi;
      const profiles = [];
      let match;
      while ((match = linkedinProfileRegex.exec(input)) !== null) {
        const handle = match[1];
        const normalizedUrl = `https://www.linkedin.com/in/${handle}`;
        profiles.push({ url: normalizedUrl, handle });
      }
      return Array.from(new Map(profiles.map((item) => [item.handle, item])).values());
    };

    setUniqueProfiles(preprocessInput(urls));
  }, [urls]);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSearchClick = async () => {
    setIsLoading(true);
    try {
      const links = uniqueProfiles.map((profile) => profile.url);
      console.log('Request body:', { links, flatten: true });

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/internal/linkedin/profiles`,
        { links, flatten: true },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': frontendApiKey,
          },
        }
      );

      setResponseData((prevData) => [...prevData, { links: uniqueProfiles, data: response.data }]);
    } catch (error) {
      console.error('Error making the request:', error.message);
      if (error.response) {
        console.error('Status code:', error.response.status);
        console.error('Response body:', error.response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (dataToDownload, index, format) => {
    // Generate a timestamp in the format YYYYMMDD_HHMMSS
    const now = new Date();
    const timestamp =
      now.getFullYear().toString() +
      ('0' + (now.getMonth() + 1)).slice(-2) +
      ('0' + now.getDate()).slice(-2) +
      '_' +
      ('0' + now.getHours()).slice(-2) +
      ('0' + now.getMinutes()).slice(-2) +
      ('0' + now.getSeconds()).slice(-2);

    let blob;
    let fileName = `linkedin_profile_${timestamp}`;

    if (format === 'json') {
      blob = new Blob([JSON.stringify(dataToDownload, null, 2)], { type: 'application/json' });
      fileName += '.json';
    } else if (format === 'csv') {
      const csv = jsonToCSV(dataToDownload);
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      fileName += '.csv';
    } else if (format === 'excel') {
      const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
      fileName += '.xlsx';
    }

    saveAs(blob, fileName);
  };

  const jsonToCSV = (data) => {
    if (!data || !data.length) return '';
    const headers = Object.keys(data[0]);
    const csvRows = [
      headers.join(','),
      ...data.map((row) =>
        headers
          .map((fieldName) => {
            const escaped = ('' + row[fieldName]).replace(/"/g, '""');
            return `"${escaped}"`;
          })
          .join(',')
      ),
    ];
    return csvRows.join('\n');
  };

  // Determine if the button should be disabled and why
  const isDisabled = !isPaidUser || uniqueProfiles.length === 0 || uniqueProfiles.length > 100;

  let disableReason = '';
  if (uniqueProfiles.length === 0) {
    disableReason = 'Please add a LinkedIn profile URL';
  } else if (!isPaidUser) {
    disableReason = 'You must be on a paid plan to use this feature';
  } else if (uniqueProfiles.length > 100) {
    disableReason = 'You have exceeded the maximum of 100 profiles';
  }

  return (
    <Box w="850px" mx="auto" mt={10} mb={100}>
      <Heading mb={4}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={handleBackClick}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            _hover={{ transform: 'translateX(-4px)' }}
            fontSize="2xl"
          />
          <Image src={linkedin} alt="LinkedIn logo" boxSize="40px" mr={4} />
          LinkedIn Profile Search Data
          <Spacer />
        </Flex>
      </Heading>

      <Box px={['10px', null, null, '0']}>
        <Textarea
          placeholder="Enter LinkedIn profile URLs (any format, duplicates will be removed)"
          value={urls}
          onChange={(e) => setUrls(e.target.value)}
          mb={4}
          bg="gray.100"
          h="300px"
          borderRadius="xl"
        />
        <Wrap spacing={2}>
          {uniqueProfiles.map((profile, index) => (
            <WrapItem key={index}>
              <Tag size="md" variant="solid" colorScheme="blue">
                {profile.handle}
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
        <Text mt={4} fontFamily="Poppins" fontSize={20} mb={4}>
          You have entered <strong>{uniqueProfiles.length}</strong> unique LinkedIn profiles.
        </Text>
        {uniqueProfiles.length > 100 && (
          <Text color="red.500" mb={4}>
            You have exceeded the maximum of 100 profiles. Please remove some URLs.
          </Text>
        )}
        <Tooltip label={disableReason} isDisabled={!isDisabled}>
          <Button
            colorScheme="blue"
            size="lg"
            isDisabled={isDisabled}
            onClick={handleSearchClick}
            isLoading={isLoading}
          >
            Search
          </Button>
        </Tooltip>

        {responseData.map((responseEntry, index) => (
          <Box key={index} mt={6} p={4} borderWidth="1px" borderRadius="md" bg="gray.100">
            <Flex justify="space-between" align="center">
              <Text>
                You found LinkedIn data for <strong>{responseEntry.links.length}</strong> profiles.
              </Text>
              <Menu>
                <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="md" colorScheme="blue">
                  Download
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'json')}>
                    JSON
                  </MenuItem>
                  <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'csv')}>
                    CSV
                  </MenuItem>
                  <MenuItem onClick={() => handleDownload(responseEntry.data, index, 'excel')}>
                    Excel
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LinkedinProfile;
