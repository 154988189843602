// Pricing.js
import React, { useEffect, useRef } from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import PricingMain from './PricingMain';
import PricingFAQ from './PricingFAQ';
import PricingEnterprise from './PricingEnterprise';
import { useToast } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

function Pricing() {
  const toast = useToast();
  const location = useLocation();
  const hasShownToast = useRef(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const upgrade = queryParams.get('upgrade');

    if (upgrade === 'true' && !hasShownToast.current) {
      toast({
        title: "Upgrade Required",
        description: "Please upgrade your account to continue.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      hasShownToast.current = true;
    }
  }, [location.search, toast]);

  return (
    <>
      <Header />
      <PricingMain />
      <PricingEnterprise />
      <PricingFAQ />
      <Footer />
    </>
  );
}

export default Pricing;
