import React from 'react';
import { Box, Heading, Text, VStack, HStack, Badge } from '@chakra-ui/react';

const Feeds = () => (
  <Box>
    <Heading as="h1" size="xl" mb={4}>Your Feeds</Heading>
    <Text mb={6}>Stay updated with the latest news and information from your selected feeds.</Text>
    <VStack spacing={4} align="stretch">
      <Box p={4} borderWidth={1} borderRadius="md">
        <Heading as="h3" size="md">Latest Tech News</Heading>
        <HStack mt={2}>
          <Badge colorScheme="green">Technology</Badge>
          <Badge colorScheme="blue">Innovation</Badge>
        </HStack>
      </Box>
      <Box p={4} borderWidth={1} borderRadius="md">
        <Heading as="h3" size="md">Industry Updates</Heading>
        <HStack mt={2}>
          <Badge colorScheme="purple">Business</Badge>
          <Badge colorScheme="orange">Market Trends</Badge>
        </HStack>
      </Box>
      <Box p={4} borderWidth={1} borderRadius="md">
        <Heading as="h3" size="md">Developer Resources</Heading>
        <HStack mt={2}>
          <Badge colorScheme="red">Tutorials</Badge>
          <Badge colorScheme="yellow">Best Practices</Badge>
        </HStack>
      </Box>
    </VStack>
  </Box>
);

export default Feeds;