import React, { useState, useMemo } from 'react';
import {
  Box,
  Heading,
  Input,
  Button,
  Grid,
  Center,
  Image,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
  Flex,
  VStack,
  Tooltip,
} from '@chakra-ui/react';
import { useNavigate, Outlet } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import { FaDatabase } from 'react-icons/fa';

import googleIcon from '../../../assets/google.png';
import linkedinIcon from '../../../assets/linkedin.png';
import websiteIcon from '../../../assets/website.png';
import instagramIcon from '../../../assets/instagram.png';
import markerIcon from '../../../assets/marker.png';
import xIcon from '../../../assets/x.png';
import amazonIcon from '../../../assets/amazon.png';
import cryptoIcon from '../../../assets/crypto.png';
import peopleIcon from '../../../assets/people.png';

function DataSources() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const navigate = useNavigate();
  const [isSmallScreen] = useMediaQuery('(max-width: 768px)');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const categories = useMemo(
    () => [
      { name: 'All', icon: FaDatabase },
      { name: 'Google', icon: googleIcon },
      { name: 'LinkedIn', icon: linkedinIcon },
      { name: 'Instagram', icon: instagramIcon },
      { name: 'X (Twitter)', icon: xIcon },
      { name: 'Amazon', icon: amazonIcon },
      { name: 'Crypto', icon: cryptoIcon },
      { name: 'Website', icon: websiteIcon },
      { name: 'People', icon: peopleIcon },
    ],
    []
  );

  const dataSources = useMemo(
    () => [
      {
        id: 1,
        name: 'Google Maps Search Data',
        icon: googleIcon,
        path: '/data/google-maps-search-data',
        disabled: false,
        category: 'Google',
      },
      {
        id: 2,
        name: 'LinkedIn Profile Search Data',
        icon: linkedinIcon,
        path: '/data/linkedin-profile-search-data',
        disabled: false,
        category: 'LinkedIn',
      },
      {
        id: 3,
        name: 'LinkedIn Company Search Data',
        icon: linkedinIcon,
        path: '/data/linkedin-company-search-data',
        disabled: false,
        category: 'LinkedIn',
      },
      {
        id: 4,
        name: 'Website Email Search Data',
        icon: websiteIcon,
        path: '/data/website-email-search-data',
        disabled: false,
        category: 'Website',
      },
      {
        id: 5,
        name: 'Get Maps Data',
        icon: markerIcon,
        path: '/getmapsdata',
        disabled: false,
        category: 'Google',
      },
      {
        id: 6,
        name: 'Search Instagram Followers List',
        icon: instagramIcon,
        path: '/data/search-instagram-followers',
        disabled: false,
        category: 'Instagram',
      },
      {
        id: 7,
        name: 'People Search Data',
        icon: peopleIcon,
        path: '/data/people-search-data',
        disabled: false,
        category: 'People',
      },
      {
        id: 8,
        name: 'Address Search Data',
        icon: peopleIcon,
        path: '/data/address-search-data',
        disabled: false,
        category: 'People',
      },
      {
        id: 14,
        name: 'Amazon Search Data',
        icon: amazonIcon,
        path: '/data/amazon-search-data',
        disabled: false,
        category: 'Amazon',
      },
      {
        id: 15,
        name: 'Google Search Data',
        icon: googleIcon,
        path: '/data/google-search-data',
        disabled: false,
        category: 'Google',
      },
      {
        id: 9,
        name: 'Search Instagram Following List',
        icon: instagramIcon,
        path: '/data/search-instagram-following',
        disabled: true,
        category: 'Instagram',
      },
      {
        id: 10,
        name: 'X (Twitter) Followers List',
        icon: xIcon,
        path: '/data/x-followers',
        disabled: true,
        category: 'X (Twitter)',
      },
      {
        id: 11,
        name: 'X (Twitter) Following List',
        icon: xIcon,
        path: '/data/x-following',
        disabled: true,
        category: 'X (Twitter)',
      },
      {
        id: 12,
        name: 'LinkedIn Jobs Data',
        icon: linkedinIcon,
        path: '/data/linkedin-jobs-data',
        disabled: true,
        category: 'LinkedIn',
      },
      {
        id: 13,
        name: 'Google Maps Reviews Data',
        icon: googleIcon,
        path: '/data/google-maps-reviews-data',
        disabled: true,
        category: 'Google',
      },
      {
        id: 16,
        name: 'Crypto Coin Information',
        icon: cryptoIcon,
        path: '/data/crypto-coin-info',
        disabled: true,
        category: 'Crypto',
      },
    ],
    []
  );

  const filteredDataSources = useMemo(() => {
    return dataSources.filter((source) => {
      const matchesSearch = source.name.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === 'All' || source.category === selectedCategory;
      return matchesSearch && matchesCategory;
    });
  }, [dataSources, searchTerm, selectedCategory]);

  const handleButtonClick = (path) => {
    navigate(path);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.05,
        duration: 0.3,
        ease: 'easeOut',
      },
    }),
  };

  return (
    <>
      <Outlet />
      {!window.location.pathname.includes('/data/') && (
        <Center>
          <Box maxWidth="1200px" my={50} px={isSmallScreen ? 4 : 8} w="100%">
            <Heading as="h1" size="3xl" textAlign="center">
              Pick Your Data Source
            </Heading>
            <InputGroup py={30} maxWidth="400px" mx="auto">
              <InputLeftElement
                pointerEvents="none"
                children={<SearchIcon color="gray.400" />}
                pl={4}
                height="100%"
                display="flex"
                alignItems="center"
              />
              <Input
                placeholder="Search data sources..."
                value={searchTerm}
                onChange={handleSearch}
                borderRadius="xl"
                size="lg"
                bg="gray.100"
              />
            </InputGroup>
            <Flex direction={isSmallScreen ? 'column' : 'row'} align="flex-start" gap={8} mt={4} minH="80vh">
              <Box minW={isSmallScreen ? '100%' : '150px'}>
                <Heading as="h2" size="md" mb={4}>
                  Categories
                </Heading>
                <VStack align="stretch" spacing={2}>
                  {categories.map((category) => (
                    <Button
                      key={category.name}
                      onClick={() => handleCategorySelect(category.name)}
                      borderWidth="none"
                      borderBottomWidth="none"
                      borderRightWidth="none"
                      bg="none"
                      leftIcon={
                        typeof category.icon === 'string' ? (
                          <Image src={category.icon} alt={`${category.name} icon`} boxSize="20px" />
                        ) : (
                          <category.icon />
                        )
                      }
                      justifyContent="flex-start"
                      width="100%"
                      py={3}
                      fontSize="md"
                    >
                      {category.name}
                    </Button>
                  ))}
                </VStack>
              </Box>
              <Box flex="1">
                <Grid
                  key={selectedCategory}
                  templateColumns={isSmallScreen ? '1fr' : 'repeat(auto-fill, minmax(250px, 1fr))'}
                  gap={6}
                  mb={10}
                >
                  {filteredDataSources.length > 0 ? (
                    filteredDataSources.map((source, index) => (
                      <motion.div
                        key={source.id}
                        custom={index}
                        initial="hidden"
                        animate="visible"
                        variants={fadeInVariants}
                      >
                        <Tooltip
                          label={source.disabled ? 'This data source is currently unavailable.' : ''}
                          isDisabled={!source.disabled}
                          hasArrow
                        >
                          <Button
                            size="lg"
                            onClick={() => handleButtonClick(source.path)}
                            fontFamily="Poppins"
                            borderWidth={3}
                            borderColor={'#222222'}
                            borderRightWidth={6}
                            borderBottomWidth={6}
                            borderRadius={10}
                            fontSize={20}
                            py={4}
                            px={4}
                            height="auto"
                            whiteSpace="normal"
                            textAlign="left"
                            colorScheme="gray"
                            color="#222222"
                            bg="white"
                            _hover={{ bg: 'gray.400' }}
                            isDisabled={source.disabled}
                            w="100%"
                            h="100%"
                            display="flex"
                            alignItems="center"
                          >
                            <Image src={source.icon} alt={source.name} mr={4} w={10} />
                            {source.name}
                          </Button>
                        </Tooltip>
                      </motion.div>
                    ))
                  ) : (
                    <Center w="100%" colSpan={3}>
                      <Box textAlign="center" py={10}>
                        <Heading size="md">No data sources found.</Heading>
                      </Box>
                    </Center>
                  )}
                </Grid>
              </Box>
            </Flex>
          </Box>
        </Center>
      )}
    </>
  );
}

export default DataSources;
