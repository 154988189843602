import React from 'react';
import { Box, Heading, Text, Button, VStack } from '@chakra-ui/react';

const ApiBuilder = () => (
  <Box>
    <Heading as="h1" size="xl" mb={4}>API Builder</Heading>
    <Text mb={6}>Create, manage, and deploy your APIs with our intuitive builder interface.</Text>
    <VStack spacing={4} align="start">
      <Button colorScheme="blue">Create New API</Button>
      <Button colorScheme="green">View Existing APIs</Button>
      <Button colorScheme="purple">API Documentation</Button>
      <Button colorScheme="orange">API Analytics</Button>
    </VStack>
  </Box>
);

export default ApiBuilder;