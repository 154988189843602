import React from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  useBreakpointValue,
  Avatar,
  AvatarGroup,
  HStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";
import underlineImage from "../../../assets/underline.png";

const MotionBox = motion(Box);
const MotionHeading = motion(Heading);
const MotionText = motion(Text);
const MotionButtonBox = motion(Box);

function HomeHero() {
  const headingSize = useBreakpointValue({ base: "2xl", md: "4xl" });
  const textSize = useBreakpointValue({ base: "xl", md: "2xl" });

  // Animation settings
  const fadeIn = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  const slideInUnderline = {
    hidden: { x: "-100%", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: 1.2,
        duration: 0.2,
        ease: "easeOut",
      },
    },
  };

  const container = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <MotionBox
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="60vh"
      mt={50}
      textAlign="center"
      px={4}
      initial="hidden"
      animate="visible"
      variants={container}
    >
      <VStack spacing={6} py={16}>
        <MotionHeading as="h1" size={headingSize} variants={fadeIn}>
          Your Data Search Is{" "}
          <Text as="span" position="relative" display="inline-block">
            <Box
              as={motion.div}
              backgroundImage={`url(${underlineImage})`}
              backgroundRepeat="no-repeat"
              backgroundPosition="bottom -15px left"
              backgroundSize="100% auto"
              position="absolute"
              left={0}
              right={0}
              h="85px"
              top={0}
              bottom={0}
              variants={slideInUnderline}
            />
            Over
          </Text>
        </MotionHeading>

        <MotionText fontSize={textSize} mb={8} mt={-2} variants={fadeIn}>
          The simplest and fastest way to get data from the internet
        </MotionText>

        <MotionButtonBox variants={fadeIn}>
          <HStack spacing={4}>
            <Button as={RouterLink} to="/data" colorScheme="blue" size="lg">
              Get Started for Free
            </Button>

            {/* <Button size="lg" bg="white">
              Book a Meeting
            </Button> */}
          </HStack>
        </MotionButtonBox>
        <MotionButtonBox variants={fadeIn}>
          <VStack spacing={4} mt={8}>
            <AvatarGroup size="md" max={5}>
              <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
              <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
              <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
              <Avatar
                name="Prosper Otemuyiwa"
                src="https://bit.ly/prosper-baba"
              />
              <Avatar
                name="Christian Nwamba"
                src="https://bit.ly/code-beast"
              />
            </AvatarGroup>

            <Text fontSize="lg" color="gray.500">
              Trusted by 10,000+ professionals
            </Text>
          </VStack>
        </MotionButtonBox>
      </VStack>
    </MotionBox>
  );
}

export default HomeHero;