import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const PrivacyMain = () => {
  return (
    <Box maxW="600px" mx="auto" mt={8} mb={16}>
      <Heading as="h1" size="2xl" mb={6}>
        Privacy Policy
      </Heading>
      <Text mb={4}>
        At ScrapeTable, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and safeguard the information you provide to us when using our data scraping services.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Information We Collect
      </Heading>
      <Text mb={4}>
        We collect information that you provide to us directly, such as your name, email address, and contact information when you sign up for our services or communicate with us.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Use of Information
      </Heading>
      <Text mb={4}>
        We use the information we collect to provide and improve our data scraping services, communicate with you about our products and services, and comply with legal obligations. WE DO NOT AFFILIATE OR ASSOCIATE WITH ANY OF THE ORGANIZATIONS MENTIONED WITH SCRAPETABLE. We use Google API to connect users and authenticate accounts. Outside of that we have no connection to outside parties or sources.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Data Security
      </Heading>
      <Text mb={4}>
        We implement industry-standard security measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Third-Party Services
      </Heading>
      <Text mb={4}>
        We may use third-party services to support our operations and improve our services. These third parties have access to the personal information necessary to perform their functions but are obligated not to disclose or use it for any other purpose.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Changes to this Privacy Policy
      </Heading>
      <Text mb={4}>
        We may update this Privacy Policy from time to time. When we do, we will revise the effective date at the bottom of this page. We encourage you to review this Privacy Policy periodically for any changes.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Contact Us
      </Heading>
      <Text mb={4}>
        If you have any questions or concerns about our Privacy Policy or data practices, please contact us at:
      </Text>
      <UnorderedList spacing={2}>
        <ListItem>Email: contact@scrapetable.com</ListItem>
      </UnorderedList>

      <Text mt={8} fontSize="sm">
        Effective Date: May 19, 2024
      </Text>
    </Box>
  );
};

export default PrivacyMain;