import React from "react";
import {
  Box,
  Flex,
  Text,
  VStack,
  Image,
  Button,
  HStack,
} from "@chakra-ui/react";

const cleanAddress = (name, fullAddress) => {
  if (!fullAddress || typeof fullAddress !== "string") return "";
  return fullAddress.startsWith(name)
    ? fullAddress.slice(name.length).replace(/^,\s*/, "").trim()
    : fullAddress.trim();
};

const shortenURL = (url) => {
  if (!url) return "";
  const trimmedUrl = url.replace(/^https?:\/\/(www\.)?/, "");
  return trimmedUrl.length > 20
    ? `${trimmedUrl.substring(0, 20)}...`
    : trimmedUrl;
};

const renderStars = (rating) => (
  <Text fontSize="md" color="yellow.400">
    {"★".repeat(Math.floor(rating))}
    <Text as="span" color="gray.300">
      {"★".repeat(5 - Math.floor(rating))}
    </Text>
  </Text>
);

const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let match;
  if (cleaned.length === 11) {
    match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    return match
      ? `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
      : phoneNumber;
  }
  match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? `+1 (${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
};

const PlaceCard = React.memo(({ place, textColor, handleAddBusiness }) => (
  <Box p={4} borderRadius="md" boxShadow="md" bg="gray.200" cursor="pointer">
    <Flex justify="space-between" align="start">
      <VStack align="start" spacing={1} flex={1}>
        <Text
          textAlign="left"
          fontWeight="bold"
          fontSize="lg"
          color={textColor}
        >
          {place.name}
        </Text>
        <Text fontSize="sm" color={textColor}>
          {formatPhoneNumber(place.phone_number)}
        </Text>
        <HStack>
          {renderStars(place.rating)}
          <Text fontSize="sm" color={textColor}>
            {place.review_count > 0
              ? `${place.rating} (${place.review_count} reviews)`
              : "(0 reviews)"}
          </Text>
        </HStack>
        {place.website && (
          <Text
            fontSize="sm"
            color="blue.500"
            as="a"
            href={place.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {shortenURL(place.website)}
          </Text>
        )}
        <Text fontSize="sm" color={textColor} textAlign="left">
          {cleanAddress(place.name, place.full_address)}
        </Text>
      </VStack>
      <VStack>
        {place.photos && place.photos.length > 0 && (
          <Image
            src={place.photos[0].src}
            alt={place.name}
            boxSize="80px"
            objectFit="cover"
            borderRadius="md"
            ml={2}
          />
        )}
        <Button
          mt={2}
          colorScheme="green"
          size="sm"
          borderWidth={3}
          borderColor="#222222"
          borderRightWidth={6}
          borderBottomWidth={6}
          borderRadius={10}
          w="100%"
          onClick={() => handleAddBusiness(place)}
        >
          Add
        </Button>
      </VStack>
    </Flex>
  </Box>
));

export default PlaceCard;
