import React from 'react';
import { Box, Grid, GridItem, Text, Heading, useColorModeValue, Center, VStack } from '@chakra-ui/react';

const useCases = [
  {
    emoji: '🔍',
    title: 'Web Data Extraction',
    description: 'Automate the extraction of data from websites to collect valuable information.',
  },
  {
    emoji: '📊',
    title: 'Market Analysis',
    description: 'Gather competitive data to analyze market trends and gain insights.',
  },
  {
    emoji: '📈',
    title: 'Price Monitoring',
    description: 'Track prices across multiple websites to ensure competitive pricing strategies.',
  },
  {
    emoji: '📚',
    title: 'Content Aggregation',
    description: 'Collect content from various sources to create comprehensive datasets.',
  },
  {
    emoji: '👥',
    title: 'Lead Generation',
    description: 'Find potential customers and generate leads by scraping contact information.',
  },
  {
    emoji: '🛍️',
    title: 'E-commerce Data',
    description: 'Extract product details, reviews, and ratings for e-commerce analysis.',
  },
];

const HomeUseCases = () => {
  const boxBg = useColorModeValue('gray.100', 'gray.700');

  return (
    <Center py={70}>
      <VStack spacing={6}>
        <Heading>Data For Every Use Case</Heading>
        <Box maxW="800px" w="100%">
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            {useCases.map((useCase, index) => (
              <GridItem key={index} bg={boxBg} p={4} borderRadius="md" boxShadow="md">
                <Text fontSize="2xl">{useCase.emoji}</Text>
                <Heading as="h3" size="md" mt={2} mb={2}>
                  {useCase.title}
                </Heading>
                <Text>{useCase.description}</Text>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </VStack>
    </Center>
  );
};

export default HomeUseCases;
