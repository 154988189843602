import React, { useState } from 'react';
import {
  Box,
  Heading,
  Button,
  Input,
  Textarea,
  FormControl,
  FormLabel,
  Center,
  useToast,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { functions } from '../../../Firebase';
import { httpsCallable } from 'firebase/functions';

const ContactMain = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with values:', { name, email, message });

    setIsLoading(true);
    const sendEmail = httpsCallable(functions, 'sendEmail-sendEmail');

    try {
      const response = await sendEmail({ name, email, message });
      console.log('Email sent successfully:', response);
      toast({
        title: 'Message Sent',
        description: 'Your message has been submitted. We will be in touch soon!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setName('');
      setEmail('');
      setMessage('');
    } catch (error) {
      console.error('Error sending email:', error);
      toast({
        title: 'Error',
        description: 'There was an error sending your message. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Center>
      <Box mb={100}>
        <Heading as="h1" size="3xl" pt={20} pb={10} textAlign="center">
          Contact
        </Heading>
        <Box as="form" onSubmit={handleSubmit} maxWidth="500px" mx="auto" mb={100}>
          <Text mb={10} align="center">
            For error reporting, help, and feature requests, please feel free to send us a message!
          </Text>
          <FormControl id="name" mb={4}>
            <FormLabel>Name</FormLabel>
            <Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              size="lg"
              fontFamily="Poppins"
              borderRadius="10"
              bg="gray.300"
              placeholder="Enter your name"
              _hover={{ bg: 'gray.300' }}
              _focus={{ boxShadow: 'none', borderColor: 'gray.300' }}
              w="500px"
            />
          </FormControl>
          <FormControl id="email" mb={4}>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              size="lg"
              fontFamily="Poppins"
              borderRadius="10"
              bg="gray.300"
              placeholder="Enter your email"
              _hover={{ bg: 'gray.300' }}
              _focus={{ boxShadow: 'none', borderColor: 'gray.300' }}
              w="500px"
            />
          </FormControl>
          <FormControl id="message" mb={4}>
            <FormLabel>Message</FormLabel>
            <Textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              size="lg"
              fontFamily="Poppins"
              borderRadius="10"
              bg="gray.300"
              placeholder="Enter your message"
              _hover={{ bg: 'gray.300' }}
              _focus={{ boxShadow: 'none', borderColor: 'gray.300' }}
              w="500px"
            />
          </FormControl>
          <Center mt={8}>
            <Button
              colorScheme="blue"
              size="lg"
              type="submit"
              borderWidth={3}
              borderColor={'#222222'}
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              fontSize={20}
              py={4}
              w="500px"
              px={4}
              isDisabled={isLoading}
            >
              {isLoading ? <Spinner /> : 'Submit'}
            </Button>
          </Center>
        </Box>
      </Box>
    </Center>
  );
};

export default ContactMain;
