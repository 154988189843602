import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useToast,
  HStack,
  IconButton,
  Spinner,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  Text,
} from '@chakra-ui/react';
import { DeleteIcon, RepeatIcon, CopyIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';

const API_FETCH_URL = process.env.REACT_APP_API_FETCH_URL || 'http://localhost:5000';

const DashboardAPI = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newApiKey, setNewApiKey] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isOpen: false, keyId: null });
  const { user } = useAuth();

  const fetchApiKeys = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    try {
      const response = await axios.post(`${API_FETCH_URL}/get-api-keys`, { uid: user.uid });
      setApiKeys(response.data.apiKeys || []);
    } catch (err) {
      console.error('Error fetching API keys:', err);
      setError(err.response?.data?.message || 'Failed to fetch API keys.');
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchApiKeys();
  }, [fetchApiKeys]);

  const handleGenerateApiKey = async () => {
    if (!user) return;

    setLoading(true);
    try {
      const response = await axios.post(`${API_FETCH_URL}/generate-api-key`, { uid: user.uid });
      setNewApiKey(response.data.apiKey);
      onOpen();
      fetchApiKeys();
      toast({
        title: 'API Key Generated',
        description: 'A new API key has been generated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Error generating new API key:', err);
      setError(err.response?.data?.message || 'Failed to generate API key.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteApiKey = async (keyId) => {
    if (!user) return;

    setLoading(true);
    try {
      await axios.post(`${API_FETCH_URL}/delete-api-key`, { uid: user.uid, keyId });
      fetchApiKeys();
      toast({
        title: 'API Key Deleted',
        description: 'The API key has been successfully deleted.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      console.error('Error deleting API key:', err);
      setError(err.response?.data?.message || 'Failed to delete API key.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(newApiKey);
    toast({
      title: 'API Key Copied',
      description: 'The API key has been copied to your clipboard.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Box>
      {error && (
        <Alert status="error" borderRadius={10} mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      <FormControl>
        {loading ? (
          <Center h="100%">
            <Spinner size="xl" />
          </Center>
        ) : (
          <VStack align="stretch">
            <Text fontSize="2xl" fontWeight="bold" mb={2}>
              API Keys
            </Text>
            <Button
              onClick={handleGenerateApiKey}
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              colorScheme="blue"
              leftIcon={<RepeatIcon />}
              mb={2}
            >
              Generate New API Key
            </Button>
            {apiKeys.map((key) => (
              <HStack key={key.id}>
                <Input
                  value={(key.key)}
                  isReadOnly
                  bg="gray.100"
                  borderRadius={5}
                  py={2}
                  px={4}
                  mb={2}
                />
                <IconButton
                  borderWidth={3}
                  borderColor="#222222"
                  borderRightWidth={6}
                  borderBottomWidth={6}
                  borderRadius={10}
                  colorScheme="red"
                  aria-label="Delete API key"
                  icon={<DeleteIcon />}
                  onClick={() => setDeleteConfirmation({ isOpen: true, keyId: key.id })}
                />
              </HStack>
            ))}
          </VStack>
        )}
      </FormControl>

      {/* Modal for New API Key */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>API Key Generated</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Alert status="warning">
              <AlertIcon />
              <Box>
                <AlertTitle>Important!</AlertTitle>
                <AlertDescription>
                  Store this API key securely. You won't be able to see it again.
                </AlertDescription>
              </Box>
            </Alert>
            <Box mt={4}>
              <FormLabel>New API Key</FormLabel>
              <HStack>
                <Input
                  type="text"
                  value={newApiKey}
                  isReadOnly
                  bg="gray.100"
                  borderRadius={5}
                  py={2}
                  px={4}
                />
                <IconButton
                  aria-label="Copy API key"
                  icon={<CopyIcon />}
                  onClick={handleCopyApiKey}
                  borderWidth={3}
                  borderColor="#222222"
                  borderRightWidth={6}
                  borderBottomWidth={6}
                  borderRadius={10}
                />
              </HStack>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for Delete Confirmation */}
      <Modal
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, keyId: null })}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to delete this API key?</ModalBody>
          <ModalFooter>
            <Button
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleDeleteApiKey(deleteConfirmation.keyId);
                setDeleteConfirmation({ isOpen: false, keyId: null });
              }}
            >
              Delete
            </Button>
            <Button
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              variant="ghost"
              onClick={() => setDeleteConfirmation({ isOpen: false, keyId: null })}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DashboardAPI;
