// src/components/AmazonSearch.js

import React, { useState } from 'react';
import {
  Heading, IconButton, Image, Flex, Spacer, Box, FormControl,
  FormLabel, Input, Text, Button, Menu, MenuButton, MenuList,
  MenuItem, VStack,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import amazonLogo from '../../../../../assets/amazon.png'; // Replace with your Amazon logo path
import { useAuth } from '../../../../utils/AuthContext';
import * as XLSX from 'xlsx';

const AmazonSearch = () => {
  const navigate = useNavigate();
  const { frontendApiKey } = useAuth();
  const [searchQuery, setSearchQuery] = useState('');
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = async () => {
    const requestData = {
      search: searchQuery,
    };

    setIsLoading(true);
    setResponse(null);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/internal/amazon/search`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': frontendApiKey,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (res.status === 402) {
        window.location.href = "/verify";
        return;
      }

      if (res.status === 405) {
        window.location.href = "/pricing?upgrade=true";
        return;
      }

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      setResponse(data);
    } catch (error) {
      console.error('Error:', error);
      setResponse({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (format) => {
    if (!response || (Array.isArray(response) && response.length === 0)) return;

    const dataToDownload = Array.isArray(response) ? response : [response];

    const date = new Date();
    const timestamp = `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate()
      .toString()
      .padStart(2, '0')}_${date.getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes()
      .toString()
      .padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;

    const sanitizedSearch = searchQuery.replace(/[^a-zA-Z0-9_-]/g, '');

    const filename = `${sanitizedSearch}_amazon_search_${timestamp}.${format}`;

    if (format === 'json') {
      const jsonStr = JSON.stringify(dataToDownload, null, 2);
      const blob = new Blob([jsonStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      downloadFile(url, filename);
    } else if (format === 'csv' || format === 'excel' || format === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

      if (format === 'csv') {
        const csv = XLSX.utils.sheet_to_csv(worksheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        downloadFile(url, filename);
      } else if (format === 'excel' || format === 'xlsx') {
        XLSX.writeFile(workbook, filename);
      }
    }
  };

  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Box w="850px" mx="auto" mt={10} mb={100}>
      <Heading mb={8}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => navigate(-1)}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            _hover={{ transform: 'translateX(-4px)' }}
            fontSize="2xl"
          />
          <Image src={amazonLogo} alt="Amazon logo" boxSize="40px" mr={4} />
          Amazon Product Search
          <Spacer />
        </Flex>
      </Heading>

      <FormControl id="search" isRequired mb={6}>
        <FormLabel fontWeight="bold">Product Name</FormLabel>
        <Input
          bg="gray.100"
          borderRadius="xl"
          type="text"
          placeholder="Enter product name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </FormControl>

      <Box mb={6}>
        <Text>
          Each search costs <strong>1</strong> credit.
        </Text>
      </Box>

      <Button
        colorScheme="blue"
        onClick={handleSearch}
        isLoading={isLoading}
        isDisabled={!searchQuery || isLoading}
        size="lg"
        width="auto"
      >
        Search Product
      </Button>

      {response && Array.isArray(response) && (
        <Box mt={10}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading size="lg">Results</Heading>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                colorScheme="green"
              >
                Download
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleDownload('json')}>JSON</MenuItem>
                <MenuItem onClick={() => handleDownload('csv')}>CSV</MenuItem>
                <MenuItem onClick={() => handleDownload('xlsx')}>Excel</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <VStack spacing={4} mt={4}>
            {response.slice(0, 50).map((item, index) => (
              <Box
                key={index}
                w="100%"
                p={4}
                bg="gray.100"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                alignItems="center"
              >
                <Image
                  src={item.image}
                  alt={item.title}
                  boxSize="100px"
                  objectFit="contain"
                  mr={4}
                />
                <Box flex="1">
                  <Text fontWeight="bold">{item.title}</Text>
                  <Text>Price: {item.price}</Text>
                  <Text>Rating: {item.rating}</Text>
                </Box>
                <Button
                  as="a"
                  href={item.productLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  colorScheme="blue"
                  size="sm"
                >
                  View Product
                </Button>
              </Box>
            ))}
            {response.length > 50 && (
              <Box w="100%" textAlign="center">
                + {response.length - 50} more
              </Box>
            )}
          </VStack>
        </Box>
      )}

      {response && response.error && (
        <Box mt={6}>
          <Text color="red.500">Error: {response.error}</Text>
        </Box>
      )}
    </Box>
  );
};

export default AmazonSearch;
