import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import ContactMain from './ContactMain';

function Contact() {
  return (
    <>
      <Header />
      <ContactMain/>
      <Footer/>
    </>
  );
}

export default Contact;
