// Downloads.js
import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import * as XLSX from "xlsx";
import axios from "axios";
import { useAuth } from "../../../../utils/AuthContext"; // Adjust the import path as necessary

const Downloads = ({ searchResults }) => {
  const { userPlan, frontendApiKey } = useAuth();
  const isPaid = userPlan === "paid";

  const [emailEnrichmentLoading, setEmailEnrichmentLoading] = useState({});
  const [emailEnrichmentError, setEmailEnrichmentError] = useState({});
  const [emailEnrichmentData, setEmailEnrichmentData] = useState({});

  const downloadJSON = (data, fileName) => {
    try {
      const json = JSON.stringify(data, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading JSON:", error);
    }
  };

  const downloadCSV = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error("CSV download expects an array of objects.");
        return;
      }

      const csvRows = [];
      const headers = Object.keys(data[0] || {});
      csvRows.push(headers.join(","));

      data.forEach((row) => {
        const values = headers.map((header) => {
          const value = row[header] != null ? row[header] : ''; // Replace undefined or null with empty string
          const escaped = ("" + value).replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const replaceUndefinedWithEmptyString = (data) => {
    return data.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = row[key] != null ? row[key] : ''; // Replace undefined or null with empty string
      });
      return newRow;
    });
  };
  
  const downloadExcel = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error("Excel download expects an array of objects.");
        return;
      }
  
      const processedData = replaceUndefinedWithEmptyString(data);

      const worksheet = XLSX.utils.json_to_sheet(processedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleDownload = (data, keyword, format, prefix) => {
    const now = new Date();
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = String(hours % 12 || 12).padStart(2, '0');

    const formattedDate = `${month}-${day}-${year}_${formattedHours}-${minutes}-${seconds}-${ampm}`;
    const sanitizedKeyword = keyword.replace(/\s+/g, "_");
    let fileName = `${prefix}_${sanitizedKeyword}_${formattedDate}`;
    switch (format) {
      case "json":
        fileName += ".json";
        downloadJSON(data, fileName);
        break;
      case "csv":
        fileName += ".csv";
        downloadCSV(data, fileName);
        break;
      case "excel":
        fileName += ".xlsx";
        downloadExcel(data, fileName);
        break;
      default:
        break;
    }
  };

  const handleFindWebsiteEmail = async (result) => {
    const resultId = result.id;

    try {
      setEmailEnrichmentLoading((prevState) => ({
        ...prevState,
        [resultId]: true,
      }));
      setEmailEnrichmentError((prevState) => ({
        ...prevState,
        [resultId]: null,
      }));

      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

      const response = await axios.post(
        `${apiBaseUrl}/internal/maps/enrich-emails`,
        result.data,
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": frontendApiKey,
          },
        }
      );

      setEmailEnrichmentData((prevState) => ({
        ...prevState,
        [resultId]: response.data,
      }));

      console.log("Email enrichment response:", response.data);
    } catch (error) {
      console.error("Error enriching emails:", error);
      setEmailEnrichmentError((prevState) => ({
        ...prevState,
        [resultId]: "Failed to enrich emails.",
      }));
    } finally {
      setEmailEnrichmentLoading((prevState) => ({
        ...prevState,
        [resultId]: false,
      }));
    }
  };

  if (!searchResults || searchResults.length === 0) {
    return null;
  }

  return (
    <Box mt={4}>
      <VStack spacing={4} align="stretch">
        {searchResults.map((result) => {
          const businessCount = Array.isArray(result.data)
            ? result.data.length
            : Object.keys(result.data).length;

          const emailCount = Array.isArray(result.data)
            ? result.data.filter(
                (entry) =>
                  entry.website &&
                  entry.website.trim() !== "" &&
                  entry.website.trim() !== "N/A"
              ).length
            : 0;

          const isLoading = emailEnrichmentLoading[result.id] || false;
          const enrichmentData = emailEnrichmentData[result.id];
          let emailsFound = 0;

          if (enrichmentData) {
            const countEmailsInResponse = (data) => {
              const jsonString = JSON.stringify(data);
              const emailRegex =
                /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
              const emails = jsonString.match(emailRegex) || [];
              return emails.length;
            };
            emailsFound = countEmailsInResponse(enrichmentData);
          }

          return (
            <Box
              key={result.id}
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              bg="gray.100"
            >
              <HStack alignItems="center">
                <HStack flex="1" spacing={4}>
                  <Text>
                    You found <strong>{businessCount}</strong> businesses for
                    keyword <strong>{result.keyword}</strong>.
                  </Text>
                </HStack>

                <Tooltip
                  label={
                    !isPaid
                      ? "You must be on a paid plan to find emails, social links, and additional phone numbers."
                      : emailCount === 0
                      ? "No emails available to find."
                      : `Find ${emailCount}+ emails, social links, and additional phone numbers for ${emailCount} credits.`
                  }
                  hasArrow
                >
                  <Box display="inline-block">
                    <Button
                      colorScheme="teal"
                      onClick={() =>
                        isPaid &&
                        emailCount > 0 &&
                        handleFindWebsiteEmail(result)
                      }
                      isDisabled={
                        !isPaid ||
                        emailCount === 0 ||
                        isLoading ||
                        enrichmentData
                      }
                      mr={2}
                    >
                      {isLoading ? (
                        <>
                          <Spinner size="sm" mr={2} />
                          Finding Emails...
                        </>
                      ) : emailCount > 0 ? (
                        `Find ${emailCount}+ Email${emailCount > 1 ? "s" : ""}`
                      ) : (
                        "Find Emails"
                      )}
                    </Button>
                  </Box>
                </Tooltip>

                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="md"
                    colorScheme="blue"
                  >
                    Download
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        handleDownload(
                          result.data,
                          result.keyword,
                          "json",
                          "google_maps_search"
                        )
                      }
                    >
                      JSON
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleDownload(
                          result.data,
                          result.keyword,
                          "csv",
                          "google_maps_search"
                        )
                      }
                    >
                      CSV
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleDownload(
                          result.data,
                          result.keyword,
                          "excel",
                          "google_maps_search"
                        )
                      }
                    >
                      Excel
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
              {emailEnrichmentError[result.id] && (
                <Text color="red.500" mt={2}>
                  {emailEnrichmentError[result.id]}
                </Text>
              )}
              {enrichmentData && (
                <HStack mt={4} justifyContent="space-between">
                  <Text>
                    You found <strong>{emailsFound}</strong> emails from the
                    list of businesses.
                  </Text>

                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      size="md"
                      colorScheme="green"
                      mt={2}
                    >
                      Download Enriched Data
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={() =>
                          handleDownload(
                            enrichmentData,
                            result.keyword,
                            "json",
                            "enriched_emails"
                          )
                        }
                      >
                        JSON
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleDownload(
                            enrichmentData,
                            result.keyword,
                            "csv",
                            "enriched_emails"
                          )
                        }
                      >
                        CSV
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleDownload(
                            enrichmentData,
                            result.keyword,
                            "excel",
                            "enriched_emails"
                          )
                        }
                      >
                        Excel
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              )}
            </Box>
          );
        })}
      </VStack>
    </Box>
  );
};

export default Downloads;
