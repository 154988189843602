// Credits.js

import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Text,
  Button,
  VStack,
  Link,
  Progress,
  Flex,
  Tooltip,
  Checkbox,
  Spinner,
  useToast,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../../../../utils/AuthContext";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../../../Firebase";
import { DeleteIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  FaHome,
  FaDatabase,
  FaEnvelope,
  FaChartPie,
} from "react-icons/fa";
import * as XLSX from "xlsx";

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const Credits = ({ businesses, setBusinesses }) => {
  const { user, emailVerified, userPlan, frontendApiKey } = useAuth(); // Destructure frontendApiKey
  const [realTimeUserDetails, setRealTimeUserDetails] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [downloadData, setDownloadData] = useState(null);
  const [emailSocialScraperEnabled, setEmailSocialScraperEnabled] =
    useState(false);
  const toast = useToast();
  const [itemsToShow, setItemsToShow] = useState(10);
  const businessesListRef = useRef(null);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
        const data = docSnap.data();
        setRealTimeUserDetails(data);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    setItemsToShow(10); // Reset itemsToShow when businesses change
  }, [businesses]);

  useEffect(() => {
    const list = businessesListRef.current;
    const handleScroll = () => {
      if (list.scrollTop + list.clientHeight >= list.scrollHeight - 5) {
        // User has scrolled to the bottom
        setItemsToShow((prev) => Math.min(prev + 10, businesses.length));
      }
    };

    if (list) {
      list.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (list) {
        list.removeEventListener("scroll", handleScroll);
      }
    };
  }, [businesses, itemsToShow]);

  const handleDeleteBusiness = (index) => {
    const updatedBusinesses = businesses.filter((_, i) => i !== index);
    setBusinesses(updatedBusinesses);
  };

  const handleDownloadData = async () => {
    if (!frontendApiKey) {
      toast({
        title: "API Key Missing",
        description: "Unable to perform the operation without a valid API key.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    setLoading(true);

    const dataToSend = {
      businesses: businesses,
      emails: emailSocialScraperEnabled,
    };
    console.log(dataToSend);
    console.log("Using Frontend API Key:", frontendApiKey); // Optional: Remove in production

    try {
      const response = await fetch(
        `${apiBaseUrl}/internal/get-maps-data-enrich`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "api-key": frontendApiKey, // Use frontendApiKey from context
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      setDownloadData(responseData);

      toast({
        title: "Success",
        description: `Scraped ${businesses.length} businesses.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast({
        title: "Error",
        description: "Failed to scrape data.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setLoading(false);
    }
  };

  // Calculate the number of businesses with a non-empty website field
  const numberOfBusinessesWithWebsite = businesses.filter(
    (business) => business.website && business.website.trim() !== ""
  ).length;

  // Calculate total credits
  const totalCredits =
    businesses.length * 2 +
    (emailSocialScraperEnabled ? numberOfBusinessesWithWebsite : 0);

  const isExceedingLimit =
    realTimeUserDetails &&
    realTimeUserDetails.status === "free" &&
    realTimeUserDetails.usage + totalCredits > realTimeUserDetails.limit;

  // Download functions

  const downloadJSON = (data, fileName) => {
    try {
      const json = JSON.stringify(data, null, 2);
      const blob = new Blob([json], { type: "application/json" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading JSON:", error);
    }
  };

  const downloadCSV = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error("CSV download expects an array of objects.");
        return;
      }

      const csvRows = [];
      const headers = Object.keys(data[0] || {});
      csvRows.push(headers.join(","));

      data.forEach((row) => {
        const values = headers.map((header) => {
          const value = row[header] != null ? row[header] : ""; // Replace undefined or null with empty string
          const escaped = ("" + value).replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      });

      const csvContent = csvRows.join("\n");
      const blob = new Blob([csvContent], { type: "text/csv" });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };

  const replaceUndefinedWithEmptyString = (data) => {
    return data.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        newRow[key] = row[key] != null ? row[key] : ""; // Replace undefined or null with empty string
      });
      return newRow;
    });
  };

  const downloadExcel = (data, fileName) => {
    try {
      if (!Array.isArray(data)) {
        console.error("Excel download expects an array of objects.");
        return;
      }

      const processedData = replaceUndefinedWithEmptyString(data);

      const worksheet = XLSX.utils.json_to_sheet(processedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const blob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleDownload = (data, format) => {
    const now = new Date();
    const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    const month = months[now.getMonth()];
    const day = String(now.getDate()).padStart(2, '0');
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = String(hours % 12 || 12).padStart(2, '0');

    const formattedDate = `${month}-${day}-${year}_${formattedHours}-${minutes}-${seconds}-${ampm}`;
    let fileName = `get_maps_data_${formattedDate}`;
    switch (format) {
      case "json":
        fileName += ".json";
        downloadJSON(data, fileName);
        break;
      case "csv":
        fileName += ".csv";
        downloadCSV(data, fileName);
        break;
      case "excel":
        fileName += ".xlsx";
        downloadExcel(data, fileName);
        break;
      default:
        break;
    }
  };

  return (
    <Box
      position="absolute"
      top="15px"
      right="15px"
      bg="#FFFCF4"
      p={6}
      borderRadius="25px"
      boxShadow="md"
      textAlign="center"
      w="400px"
    >
      <Flex justify="space-between" mb={4}>
        <Tooltip label="Home" placement="top">
          <Link
            pl={4}
            href="https://scrapetable.com/"
            color="gray.400"
            _hover={{ color: "gray.700" }}
          >
            <FaHome size="1.5em" />
          </Link>
        </Tooltip>
        <Tooltip label="Studio" placement="top">
          <Link
            href="https://scrapetable.com/studio"
            color="gray.400"
            _hover={{ color: "gray.700" }}
          >
            <FaChartPie size="1.5em" />
          </Link>
        </Tooltip>
        <Tooltip label="Contact Us" placement="top">
          <Link
            href="https://scrapetable.com/contact-us"
            color="gray.400"
            _hover={{ color: "gray.700" }}
          >
            <FaEnvelope size="1.5em" />
          </Link>
        </Tooltip>
        <Tooltip label="Data" placement="top">
          <Link
            pr={4}
            href="https://scrapetable.com/data"
            color="gray.400"
            _hover={{ color: "gray.700" }}
          >
            <FaDatabase size="1.5em" />
          </Link>
        </Tooltip>
      </Flex>
      {user && realTimeUserDetails && (
        <Box>
          <Flex justify="center" textAlign="left" direction="column">
            <Text mb={2} fontWeight="bold">
              Credits: {realTimeUserDetails.usage}/{realTimeUserDetails.limit}
            </Text>
            <Box
              width="100%"
              borderRadius="25px"
              borderWidth="3px"
              borderBottomWidth="6px"
              borderRightWidth="6px"
              borderColor="#222222"
            >
              <Progress
                value={
                  (realTimeUserDetails.usage / realTimeUserDetails.limit) * 100
                }
                colorScheme={
                  realTimeUserDetails.usage >= realTimeUserDetails.limit
                    ? "red"
                    : "blue"
                }
                borderRadius="25px"
                height="12px"
              />
            </Box>
          </Flex>
        </Box>
      )}
      {!user && (
        <Box>
          <Link as={RouterLink} to="/login" mr={4}>
            <Button
              colorScheme="blue"
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              w={165}
            >
              Login
            </Button>
          </Link>
          <Link as={RouterLink} to="/signup">
            <Button
              colorScheme="white"
              backgroundColor="white"
              color="#222222"
              w={165}
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
            >
              Sign Up
            </Button>
          </Link>
        </Box>
      )}
      {businesses && businesses.length > 0 && (
        <Box
          ref={businessesListRef}
          bg="gray.200"
          mt={4}
          maxH="200px"
          overflowY="auto"
          w="100%"
          p={2}
          borderWidth="1px"
          borderRadius="md"
          css={{
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <VStack spacing={2} align="stretch">
            {businesses.slice(0, itemsToShow).map((business, index) => (
              <Flex
                key={index}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                borderBottomWidth="1px"
                borderColor="gray.300"
              >
                <VStack align="left" mr={2}>
                  <Text textAlign="left" fontWeight="bold" fontSize="14px">
                    {business.name}
                  </Text>
                  <Text textAlign="left" fontSize="8px">
                    {business.full_address}
                  </Text>
                </VStack>
                <IconButton
                  icon={<DeleteIcon />}
                  size="md"
                  colorScheme="red"
                  onClick={() => handleDeleteBusiness(index)}
                  borderColor="#222222"
                  borderWidth="3px"
                  borderRadius={10}
                  borderBottomWidth={6}
                  borderRightWidth={6}
                />
              </Flex>
            ))}
          </VStack>
        </Box>
      )}
      {businesses && businesses.length > 0 && (
        <>
          <VStack spacing={4} mt={4} align="left">
            <Tooltip
              isOpen={hoveredItem === "emailSocialScraper"}
              label={
                userPlan === "paid"
                  ? "Scrape emails, social links, and additional phone numbers."
                  : "Please upgrade to scrape emails, social media links and phone numbers."
              }
              placement="left"
            >
              <Checkbox
                isChecked={emailSocialScraperEnabled}
                onChange={(e) => setEmailSocialScraperEnabled(e.target.checked)}
                onMouseEnter={() => setHoveredItem("emailSocialScraper")}
                onMouseLeave={() => setHoveredItem(null)}
                isDisabled={userPlan !== "paid"} // Disable if not paid
              >
                Email Social Scraper
              </Checkbox>
            </Tooltip>
          </VStack>
          <Box mt={4} textAlign="left">
            <Text>
              Scraping <strong>{businesses.length}</strong> businesses
              {emailSocialScraperEnabled && (
                <>
                  {" "}
                  with emails, socials, and phone numbers
                </>
              )}{" "}
              for <strong>{totalCredits}</strong> credits.
            </Text>
            <Tooltip
              label={
                !user
                  ? "You must be logged in to scrape this data"
                  : !emailVerified
                  ? "Please verify your email to use this scraper"
                  : isExceedingLimit
                  ? "Please upgrade to scrape this many businesses"
                  : ""
              }
              placement="top"
              isDisabled={
                user && emailVerified && !isExceedingLimit
              } // Disable tooltip if no message
            >
              <span> {/* Tooltip requires a single child */}
                <Button
                  mt={2}
                  colorScheme="blue"
                  isDisabled={!user || !emailVerified || loading || isExceedingLimit}
                  onClick={handleDownloadData}
                  borderColor="#222222"
                  borderWidth="3px"
                  borderRadius={10}
                  borderBottomWidth={6}
                  borderRightWidth={6}
                >
                  {loading ? <Spinner size="sm" /> : "Scrape"}
                </Button>
              </span>
            </Tooltip>
          </Box>
          {downloadData && (
            <>
              <HStack
                mt={4}
                bg="gray.100"
                justifyContent="space-between"
                borderWidth="1px"
                borderRadius="md"
                p={2}
              >
                <Text alignContent="left" align="left">
                  <strong>{downloadData.length}</strong> businesses scraped.
                </Text>
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                    size="sm"
                    colorScheme="blue"
                  >
                    Download
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() => handleDownload(downloadData, "csv")}
                    >
                      CSV
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownload(downloadData, "excel")}
                    >
                      Excel
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownload(downloadData, "json")}
                    >
                      JSON
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Credits;
