import React, { useEffect, useState } from 'react';
import { Box, Text, Spinner, Heading, Link, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom'; // Import Link from react-router-dom

const BlogHomeMain = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_URL = 'https://stable-fish-86605475e6.strapiapp.com/api/articles';
  const API_KEY = 'f00fedfc06c541a9078c68d74388b5e4c6bfdf99856196c8687eeb92147eef907e8a05e41f73a301c97b2a179274d4928ea1556fd3e18d8f7d0c770fdad272c6d5336c73e0d074eb4362c47c92170483d8122dad3bc4e28d4cdca9a39a73d8c8c4253889e13f581b9ec0a4a984b4b4f0c4b402654ed846b6c1021026deb98408'; // Use your actual API key here

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch(API_URL, {
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch articles');
        }

        const data = await response.json();
        setArticles(data.data || []); // Assuming the articles are under `data.data`
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) {
    return <Spinner size="xl"/>;
  }

  if (error) {
    return <Text color="red.500" h="100vh">{error}</Text>;
  }

  return (
    <Box p={4} h="100vh">
      <Heading as="h2" size="lg" mb={4}>
        Articles
      </Heading>
      {articles.length === 0 ? (
        <Text>No articles found</Text>
      ) : (
        articles.map((article) => {
          const { title, slug, id, locale } = article;
          const imageUrl = locale; // Assuming locale is where the image URL is stored. Update if needed.

          return title && slug ? (
            <Link
              as={RouterLink}
              to={`/blog/${slug}`} // Use slug for the URL
              key={id}
              _hover={{ textDecoration: 'none' }}
            >
              <Box borderWidth="1px" borderRadius="lg" p={4} mb={4} cursor="pointer" _hover={{ bg: 'gray.100' }}>
                {imageUrl && (
                  <Image src={imageUrl} alt={title} borderRadius="md" mb={4} />
                )}
                <Text fontWeight="bold">{title}</Text>
              </Box>
            </Link>
          ) : null;
        })
      )}
    </Box>
  );
};

export default BlogHomeMain;
