import React from 'react';
import { Box, Heading, Text, UnorderedList, ListItem } from '@chakra-ui/react';

const TermsMain = () => {
  return (
    <Box maxW="600px" mx="auto" mt={8} mb={16}>
      <Heading as="h1" size="2xl" mb={6}>
        Terms of Service
      </Heading>
      <Text mb={4}>
        Welcome to ScrapeTable! These Terms of Service govern your use of our data scraping services. By using our services, you agree to be bound by these terms. We opperate under HORIZON LAS LLC. The data you collect from our service should not be used for commercial use and is not owned or operated by scrapetable. Scrapetable is meerly a liason to connect people with public data in a quicker fashion. We do not obtain any information that isnt publically available through free or otherwise means. By using scrapetable you adhere to the policies set by the respective platforms as it comes to data use.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Acceptable Use
      </Heading>
      <Text mb={4}>
        You agree to use our services in compliance with all applicable laws and regulations. You will not scrape, collect, or use data in a way that violates any laws, regulations, or third-party rights, including intellectual property rights. We use only public sources to scrape data. If at any point in time infingement of data is breached scrapetable is not held accountable for the distribution of data. We are meerly an access point to distibute data that is freely and publically available,
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Data Privacy and Security
      </Heading>
      <Text mb={4}>
        We take data privacy and security seriously. We will never sell, rent, or share your personal information with third parties without your consent. We implement industry-standard security measures to protect your data.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Service Availability
      </Heading>
      <Text mb={4}>
        We strive to provide uninterrupted service, but we cannot guarantee continuous availability. We reserve the right to modify, suspend, or discontinue our services at any time, with or without notice.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Limitation of Liability
      </Heading>
      <Text mb={4}>
        ScrapeTable will not be liable for any indirect, incidental, consequential, special, or exemplary damages arising out of or in connection with your use of our services, even if we have been advised of the possibility of such damages.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Changes to Terms of Service
      </Heading>
      <Text mb={4}>
        We may update these Terms of Service from time to time. When we do, we will revise the effective date at the bottom of this page. We encourage you to review these Terms of Service periodically for any changes.
      </Text>

      <Heading as="h2" size="lg" mt={8} mb={4}>
        Contact Us
      </Heading>
      <Text mb={4}>
        If you have any questions or concerns about our Terms of Service, please contact us at:
      </Text>
      <UnorderedList spacing={2}>
        <ListItem>Email: contact@scrapetable.com</ListItem>
      </UnorderedList>

      <Text mt={8} fontSize="sm">
        Effective Date: May 19, 2024
      </Text>
    </Box>
  );
};

export default TermsMain;