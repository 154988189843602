import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './components/utils/AuthContext';

import ProtectedRoute from './components/utils/ProtectedRoute';
import DirectRoute from './components/utils/DirectRoute';

import Home from './components/pages/home-page/Home';
import Pricing from './components/pages/pricing-page/Pricing';
import Login from './components/pages/login-page/Login';
import SignUp from './components/pages/signup-page/SignUp';

import Data from './components/pages/data-page/Data';
import DashboardMain from './components/pages/dashboard-page/Dashboard';
import DashboardAccount from './components/pages/dashboard-page/DashboardAccount';
import DashboardAPI from './components/pages/dashboard-page/DashboardAPI';
import DashboardSubscription from './components/pages/dashboard-page/DashboardSubscription';
import Privacy from './components/pages/privacy-page/Privacy';
import Terms from './components/pages/terms-page/Terms';
import Contact from './components/pages/contact-page/Contact';
import PeopleSearch from './components/pages/data-page/sources-page/people-search-data/PeopleSearch';
import AddressSearch from './components/pages/data-page/sources-page/address-search-data/AddressSearch';
import StudioEntry from './components/studio/StudioEntry';
import Dashboard from './components/studio/tabs/Dashboard';
import Feeds from './components/studio/tabs/Feeds';
import ApiBuilder from './components/studio/tabs/APIBuilder';
import Results from './components/studio/tabs/Results';
import Google from './components/pages/data-page/sources-page/google-maps-search-data.js/Google';
import Blog from './components/pages/blog-page/BlogHome';
import BlogPost from './components/pages/blog-page/BlogPost';
import GetMapsData from './components/pages/data-page/sources-page/get-maps-data/GetMapsData';
import WebsiteEmail from './components/pages/data-page/sources-page/website-email-search-data/WebsiteEmail';
import LinkedinProfile from './components/pages/data-page/sources-page/linkedin-profile-search-data/LinkedinProfile';
import LinkedinCompany from './components/pages/data-page/sources-page/linkedin-company-search-data/LinkedinCompany';
import Verify from './components/pages/verify-page/Verify';
import InstagramFollowers from './components/pages/data-page/sources-page/instagram-followers/InstagramFollowers';
import AmazonSearch from './components/pages/data-page/sources-page/amazon-search-data/AmazonSearch';
import GoogleSearch from './components/pages/data-page/sources-page/google-search-data/GoogleSearch';



const theme = extendTheme({
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
  components: {
    Button: {
      baseStyle: {
        borderWidth: 3,
        borderColor: "#222222",
        borderRightWidth: 6,
        borderBottomWidth: 6,
        borderRadius: 10,
        fontWeight: 'bold'
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>

        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/verify" element={<Verify />} />
              <Route path="/getmapsdata" element={<GetMapsData />} />
              <Route path="/data" element={<Data />}>
                <Route path="people-search-data" element={<PeopleSearch />} />
                <Route path="address-search-data" element={<AddressSearch />} />
                <Route path="google-maps-search-data" element={<Google />} />
                <Route path="website-email-search-data" element={<WebsiteEmail />} />
                <Route path="linkedin-profile-search-data" element={<LinkedinProfile />} />
                <Route path="linkedin-company-search-data" element={<LinkedinCompany />} />
                <Route path="search-instagram-followers" element={<InstagramFollowers />} />
                <Route path="amazon-search-data" element={<AmazonSearch />} />
                <Route path="google-search-data" element={<GoogleSearch />} />
              </Route>
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:slug" element={<BlogPost />} />
              <Route path="/results" element={<Pricing />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-of-service" element={<Terms />} />

              <Route path="/results" element={<Pricing />} />

              <Route path="/login" element={<DirectRoute><Login /></DirectRoute>} />
              <Route path="/signup" element={<DirectRoute><SignUp /></DirectRoute>} />
              <Route path="/dashboard" element={<ProtectedRoute><DashboardMain /></ProtectedRoute>}>
                <Route index element={<Navigate to="account" />} /> 
                <Route path="account" element={<DashboardAccount />} />
                <Route path="apikey" element={<DashboardAPI />} />
                <Route path="subscription" element={<DashboardSubscription />} />
              </Route>
              <Route path="/studio" element={<StudioEntry />}>
                <Route index element={<Navigate to="dashboard" />} /> 
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="results" element={<Results />} />
                <Route path="api" element={<ApiBuilder />} />
                <Route path="feeds" element={<Feeds />} />
              </Route>
            </Routes>
          </Router>
        </AuthProvider>
    </ChakraProvider>
  );
}

export default App;