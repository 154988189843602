import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import BlogMain from './BlogHomeMain';

function Blog() {
  return (
    <>
      <Header />
      <BlogMain/>
      <Footer/>
    </>
  );
}

export default Blog;
