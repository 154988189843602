import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Button, Text, Alert, AlertIcon, AlertTitle, AlertDescription, VStack, Stat, StatLabel, StatNumber, StatGroup, Badge } from '@chakra-ui/react';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../utils/AuthContext';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../../Firebase';

// Utility function to format numbers with commas
const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const SubscriptionDetails = () => {
  const { user } = useAuth();
  const [limit, setLimit] = useState('');
  const [usage, setUsage] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [error, setError] = useState('');
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [hasCustomerId, setHasCustomerId] = useState(false);
  const [planName, setPlanName] = useState('Free');

  const plans = useMemo(() => [
    {
      name: 'Free',
      price: '$0',
      features: ['300 Rows'],
      unitPriceId: 'Free',
      monthlyPriceId: 'Free',
      limit: 300,
    },
    {
      name: 'Basic Monthly',
      price: '$19/m',
      features: ['3,000 Rows per month', '0.01 per additional row'],
      unitPriceId: 'price_1PSbQyDWaiv6TkQpZ2wa01df',
      monthlyPriceId: 'price_1PLcPeDWaiv6TkQpRVkV959Q',
      limit: 3000,
    },
    {
      name: 'Pro Monthly',
      price: '$69/m',
      features: ['15,000 Rows per month', '0.007 per additional row'],
      unitPriceId: 'price_1PSbRfDWaiv6TkQpSxjJean2',
      monthlyPriceId: 'price_1PLcQGDWaiv6TkQp2MJnQwCk',
      limit: 15000,
    },
    {
      name: 'Mega Monthly',
      price: '$420/m',
      features: ['120,000 Rows per month', '0.005 per additional row'],
      unitPriceId: 'price_1PSbS5DWaiv6TkQpVoQbxwQV',
      monthlyPriceId: 'price_1PLcR2DWaiv6TkQpcsJA9ZGn',
      limit: 120000,
    },
    {
      name: 'Basic Yearly',
      price: '$190',
      priceSuffix: '/y',
      features: ['3,000 Credits per month', '0.01 per additional credit', 'Everything in previous tier', '10 URL Google Maps Search', "Website Data Email Search", "Linkedin Profiles And Company"],
      unitPriceId: 'price_1PgcuxDWaiv6TkQpokL47iwK',
      monthlyPriceId: 'price_1PgcstDWaiv6TkQpPzokD2JH',
      limit: 36000,
      priceId: 'Basic',
    },
    {
      name: 'Pro Yearly',
      price: '$690',
      priceSuffix: '/y',
      features: ['15,000 Credits per month', '0.007 per additional credit', 'Priority support', 'Everything in previous tier'],
      unitPriceId: 'price_1PgcwNDWaiv6TkQpMjqyd9BR',
      monthlyPriceId: 'price_1Pgcw4DWaiv6TkQpRQlDRwtr',
      limit: 180000,
      priceId: 'Pro',
    },
    {
      name: 'Mega Yearly',
      price: '$4200',
      priceSuffix: '/y',
      features: ['120,000 Credits per month', '0.005 per additional credit', 'White glove help and support',  'Everything in previous tier'],
      unitPriceId: 'price_1PgcxvDWaiv6TkQptQi8idcc',
      monthlyPriceId: 'price_1PgcxZDWaiv6TkQplVwNBr3B',
      limit: 1440000,
      priceId: 'Mega',
    },
  ], []);

  const getPlanName = useCallback((priceIds) => {
    for (const plan of plans) {
      if (priceIds.includes(plan.unitPriceId) || priceIds.includes(plan.monthlyPriceId)) {
        return plan.name;
      }
    }
    return 'Free';
  }, [plans]);

  useEffect(() => {
    if (user) {
      const fetchSubscriptionDetails = async () => {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();
        
        // Format limit and usage with commas
        setLimit(formatNumberWithCommas(userData.limit || 0));
        setUsage(formatNumberWithCommas(userData.usage || 0));
        
        setStart(userData.currentPeriodStart
          ? new Date(userData.currentPeriodStart * 1000).toLocaleString()
          : '');
        setEnd(userData.currentPeriodEnd
          ? new Date(userData.currentPeriodEnd * 1000).toLocaleString()
          : '');
        setHasCustomerId(!!userData.customerId);
        setPlanName(getPlanName(userData.priceIds || []));
      };

      fetchSubscriptionDetails();
    }
  }, [user, getPlanName]);

  const handleOpenStripePortal = async () => {
    setIsStripeLoading(true);
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (userData.customerId) {
        const createPortalLink = httpsCallable(functions, 'helloScrapeTable');
        const { data } = await createPortalLink({ customerId: userData.customerId });
        window.open(data.url, '_blank');
      } else {
        setError('No customer ID found for the current user');
      }
    } else {
      setError('User not logged in');
    }
    setIsStripeLoading(false);
  };

  return (
    <Box>
      {error && (
        <Alert status="error" borderRadius="lg" mb={6}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle fontSize="lg">Error!</AlertTitle>
            <AlertDescription display="block">{error}</AlertDescription>
          </Box>
        </Alert>
      )}
      
      <VStack spacing={6} align="stretch">
        <Box>
          <Text fontSize="2xl" fontWeight="bold" mb={2}>Subscription Details</Text>
          <Badge colorScheme={planName === 'Free' ? 'gray' : 'green'} fontSize="md" p={2} borderRadius="md">
            {planName} Plan
          </Badge>
        </Box>

        <StatGroup>
          <Stat>
            <StatLabel fontSize="lg">Limit</StatLabel>
            <StatNumber fontSize="3xl">{limit}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel fontSize="lg">Usage</StatLabel>
            <StatNumber fontSize="3xl">{usage}</StatNumber>
          </Stat>
        </StatGroup>

        {start && end && (
          <Box 
            borderWidth={1} 
            borderColor="gray.200" 
            borderRadius="lg" 
            p={4}
          >
            <Text fontWeight="semibold" mb={2}>Current Period</Text>
            <Text>Start: {start}</Text>
            <Text>End: {end}</Text>
          </Box>
        )}
      </VStack>

      {hasCustomerId && (
        <Button
          colorScheme="purple"
          mt={6}
          onClick={handleOpenStripePortal}
          isDisabled={isStripeLoading}
          size="lg"
          width="full"
          fontWeight="bold"
          borderWidth={3}
          borderColor={'#222222'}
          borderRightWidth={6}
          borderBottomWidth={6}
          borderRadius={10}
          transition="all 0.2s"
        >
          {isStripeLoading ? "Please Wait..." : "Manage Subscription"}
        </Button>
      )}
    </Box>
  );
};

export default SubscriptionDetails;
