import React from 'react';
import { Box, Image, HStack, keyframes } from '@chakra-ui/react';
import amazonIcon from '../../../assets/amazongray.png';
import openaiIcon from '../../../assets/openai.png';
import microsoftIcon from '../../../assets/microsoft.png';

// Define the scroll animation
const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const HomeScroll = () => {
  // Array of logo objects
  const logos = [
    { src: amazonIcon, alt: 'Amazon' },
    { src: openaiIcon, alt: 'OpenAI' },
    { src: microsoftIcon, alt: 'Microsoft' },
  ];

  // Duplicate the logos array for seamless scrolling
  const duplicatedLogos = [...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos, ...logos];

  return (
    <Box overflow="hidden" width="100%" position="relative">
      <Box
        display="flex"
        animation={`${scroll} 30s linear infinite`}
        whiteSpace="nowrap"
      >
        <HStack spacing={12} justify="center">
          {duplicatedLogos.map((logo, index) => (
            <Image key={`logo-${index}`} src={logo.src} alt={logo.alt} height="100px" />
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default HomeScroll;
