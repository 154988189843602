import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import DashboardMain from './DashboardMain';

function Home() {
  return (
    <>
      <Header />
      <DashboardMain />
      <Footer/>
    </>
  );
}

export default Home;
