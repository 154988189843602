import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  Image,
  Center,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import googleIcon from "../../../assets/google.png";
import { useAuth } from "../../utils/AuthContext";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../Firebase";

const LoginMain = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { login, googleSignIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      navigate("/data");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      await googleSignIn();
      navigate("/data");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setError("Please enter your email to send a password reset request!");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Success!",
        description: "Password reset email sent. Please check your inbox.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsForgotPassword(false);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Box maxWidth="400px" margin="auto" pt={20} pb={60}>
      <VStack spacing={4}>
        <Text fontSize="4xl" fontWeight="bold">
          {isForgotPassword ? "Reset Password" : "Login"}
        </Text>
        {error && (
          <Alert status="error" borderRadius={10}>
            <AlertIcon />
            <AlertTitle mr={2}>Error!</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}
        {!isForgotPassword ? (
          <form onSubmit={handleLogin}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                bg="gray.300"
                _hover={{ bg: "gray.300" }}
                _focus={{ boxShadow: "none", borderColor: "gray.300" }}
                borderRadius={20}
                py={6}
                px={4}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                bg="gray.300"
                _hover={{ bg: "gray.300" }}
                _focus={{ boxShadow: "none", borderColor: "gray.300" }}
                borderRadius={20}
                py={6}
                px={4}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              color="white"
              fontFamily="Poppins"
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              fontSize={18}
              py={6}
              px={8}
              mt={6}
              w={400}
            >
              Login
            </Button>
          </form>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleForgotPassword();
            }}
          >
            <FormControl>
              <FormLabel>
                Enter your email associated with your account
              </FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                bg="gray.300"
                _hover={{ bg: "gray.300" }}
                _focus={{ boxShadow: "none", borderColor: "gray.300" }}
                borderRadius={20}
                py={6}
                px={4}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme="blue"
              color="white"
              fontFamily="Poppins"
              borderWidth={3}
              borderColor="#222222"
              borderRightWidth={6}
              borderBottomWidth={6}
              borderRadius={10}
              fontSize={18}
              py={6}
              px={8}
              mt={6}
              w={400}
            >
              Send Reset Email
            </Button>
          </form>
        )}
        {!isForgotPassword && (
          <>
            <Text fontSize="sm" mt={-2}>
              Don't have an account?{" "}
              <Link to="/signup" style={{ color: "#3182ce" }}>
                Sign up!
              </Link>
            </Text>
            <Text
              fontSize="sm"
              color="#3182ce"
              cursor="pointer"
              mt={-2}
              onClick={() => setIsForgotPassword(true)}
            >
              Forgot your password?
            </Text>
            <Center>
              <Button
                onClick={handleGoogleLogin}
                leftIcon={<Image src={googleIcon} alt="Google" boxSize={6} />}
                colorScheme="white"
                backgroundColor="white"
                color="#222222"
                fontFamily="Poppins"
                borderWidth={3}
                borderColor="#222222"
                borderRightWidth={6}
                borderBottomWidth={6}
                borderRadius={10}
                fontSize={18}
                py={6}
                px={8}
                w={400}
                mt={4}
                _hover={{ bg: "gray.400" }}
              >
                Continue With Google
              </Button>
            </Center>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default LoginMain;
