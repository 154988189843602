import React from 'react';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleResultsClick = () => {
    navigate('/studio/results');
  };

  return (
    <Box>
      <Heading as="h1" size="xl" mb={4}>Welcome to the studio!</Heading>
      <Text>To view your past data, head to the results tab.</Text>
      <Button size="lg" onClick={handleResultsClick} mt={4}>Results</Button>
    </Box>
  );
};

export default Dashboard;