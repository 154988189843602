import React, { useState } from 'react';
import {
  Heading, IconButton, Image, Flex, Spacer, Box, FormControl,
  FormLabel, Input, NumberInput, NumberInputField, NumberInputStepper,
  NumberIncrementStepper, NumberDecrementStepper, Text, Button,
  Menu, MenuButton, MenuList, MenuItem, VStack,
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import instagram from '../../../../../assets/instagram.png';
import { useAuth } from '../../../../utils/AuthContext';
import * as XLSX from 'xlsx';

const InstagramFollowers = () => {
  const navigate = useNavigate();
  const { frontendApiKey } = useAuth();
  const [username, setUsername] = useState('');
  const [count, setCount] = useState(200);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const calculateCredits = (count) => {
    const credits = Math.floor(count / 5);
    return credits < 1 ? 1 : credits;
  };

  const handleSearch = async () => {
    const requestData = {
      username,
      count,
    };

    setIsLoading(true);
    setResponse(null);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/internal/instagram/followers`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'api-key': frontendApiKey,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (res.status === 405) {
        window.location.href = "/pricing?upgrade=true";
        return;
      }

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      const data = await res.json();
      setResponse(data);
    } catch (error) {
      console.error('Error:', error);
      setResponse({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = (format) => {
    if (!response || !Array.isArray(response)) return;

    const dataToDownload = response;

    const date = new Date();
    const timestamp = `${date.getFullYear()}${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${date.getDate()
      .toString()
      .padStart(2, '0')}_${date.getHours()
      .toString()
      .padStart(2, '0')}${date.getMinutes()
      .toString()
      .padStart(2, '0')}${date.getSeconds().toString().padStart(2, '0')}`;

    const sanitizedUsername = username.replace(/[^a-zA-Z0-9_-]/g, '');

    const filename = `${sanitizedUsername}_instagram_followers_${timestamp}.${format}`;

    if (format === 'json') {
      const jsonStr = JSON.stringify(dataToDownload, null, 2);
      const blob = new Blob([jsonStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      downloadFile(url, filename);
    } else if (format === 'csv' || format === 'excel' || format === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(dataToDownload);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Followers');

      if (format === 'csv') {
        const csv = XLSX.utils.sheet_to_csv(worksheet);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        downloadFile(url, filename);
      } else if (format === 'excel' || format === 'xlsx') {
        XLSX.writeFile(workbook, filename);
      }
    }
  };

  const downloadFile = (url, filename) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Box w="850px" mx="auto" mt={10} mb={100}>
      <Heading mb={8}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            onClick={() => navigate(-1)}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            _hover={{ transform: 'translateX(-4px)' }}
            fontSize="2xl"
          />
          <Image src={instagram} alt="Instagram logo" boxSize="40px" mr={4} />
          Search Instagram Followers
          <Spacer />
        </Flex>
      </Heading>

      <FormControl id="username" isRequired mb={6}>
        <FormLabel fontWeight="bold">Instagram Username</FormLabel>
        <Input
          bg="gray.100"
          borderRadius="xl"
          type="text"
          placeholder="Enter Instagram username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </FormControl>

      <FormControl id="count" isRequired mb={6}>
        <FormLabel fontWeight="bold">Number of Followers</FormLabel>
        <NumberInput
          min={1}
          max={2000}
          value={count}
          onChange={(valueString) => setCount(Number(valueString))}
        >
          <NumberInputField bg="gray.100" borderRadius="xl" placeholder="Enter number of followers" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <Box mb={6}>
        <Text>
          You are about to scrape <strong>{count}</strong> followers for{' '}
          <strong>{calculateCredits(count)}</strong> credits.
        </Text>
      </Box>

      <Button
        colorScheme="blue"
        onClick={handleSearch}
        isLoading={isLoading}
        isDisabled={!username || count < 1 || isLoading}
        size="lg"
        width="auto"
      >
        Search Followers
      </Button>

      {response && Array.isArray(response) && (
        <Box mt={10}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading size="lg">Results</Heading>
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                colorScheme="green"
              >
                Download
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => handleDownload('json')}>JSON</MenuItem>
                <MenuItem onClick={() => handleDownload('csv')}>CSV</MenuItem>
                <MenuItem onClick={() => handleDownload('xlsx')}>Excel</MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <VStack spacing={4} mt={4}>
            {response.slice(0, 50).map((user, index) => (
              <Box
                key={index}
                w="100%"
                p={4}
                bg="gray.100"
                borderWidth="1px"
                borderRadius="md"
                display="flex"
                alignItems="center"
              >
                <Box flex="1">
                  <Text fontWeight="bold">{user.username}</Text>
                  <Text>{user.full_name}</Text>
                </Box>
                <Button
                  as="a"
                  href={user.profile_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  colorScheme="blue"
                  size="sm"
                >
                  View Profile
                </Button>
              </Box>
            ))}
            {response.length > 50 && (
              <Box w="100%" textAlign="center">
                + {response.length - 50} more
              </Box>
            )}
          </VStack>
        </Box>
      )}

      {response && response.error && (
        <Box mt={6}>
          <Text color="red.500">Error: {response.error}</Text>
        </Box>
      )}
    </Box>
  );
};

export default InstagramFollowers;
