import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, FormControl, FormLabel, Input, Alert, AlertIcon, AlertTitle, AlertDescription, useToast, Spinner, Text
} from '@chakra-ui/react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { updateProfile } from 'firebase/auth';
import { useAuth } from '../../utils/AuthContext';
import { db, auth } from '../../../Firebase';

const DashboardAccount = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribeSnapshot = onSnapshot(
        userDocRef,
        (snapshot) => {
          if (snapshot.exists()) {
            const userData = snapshot.data();
            setDisplayName(userData.name || '');
            setEmail(user.email || '');
            setEmailVerified(user.emailVerified || false);
          }
          setIsLoading(false);
        },
        (error) => {
          setError(error.message);
          setIsLoading(false);
        }
      );

      return () => {
        unsubscribeSnapshot();
      };
    } else {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setIsUpdateDisabled(!hasChanges || displayName === user.displayName);
    }
  }, [displayName, hasChanges, user]);

  const handleUpdateSettings = async (e) => {
    e.preventDefault();
    try {
      if (user) {
        // Update Firestore document
        await updateDoc(doc(db, 'users', user.uid), {
          name: displayName,
        });

        // Update Firebase Auth user profile
        await updateProfile(auth.currentUser, {
          displayName: displayName
        });

        toast({
          title: "Your settings have been updated!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setHasChanges(false);
      } else {
        setError('User not logged in');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box>
      {error && (
        <Alert status="error" borderRadius={10}>
          <AlertIcon />
          <AlertTitle mr={2}>Error!</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {!emailVerified && (
        <Alert status="warning" borderRadius={10} mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Email not verified!</AlertTitle>
          <AlertDescription>
            Please verify your email. <Button colorScheme="orange" onClick={() => navigate('/verify')} ml={2}>Verify</Button>
          </AlertDescription>
        </Alert>
      )}
      <form onSubmit={handleUpdateSettings}>
        <Text fontSize="2xl" fontWeight="bold" mb={2}>Account Details</Text>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input
            type="text"
            placeholder="Please enter a name..."
            value={displayName}
            onChange={(e) => {
              setDisplayName(e.target.value);
              setHasChanges(true);
            }}
            bg="gray.100"
            borderRadius={5}
            py={2}
            px={4}
          />
        </FormControl>
        <FormControl mt={4}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            isReadOnly
            bg="gray.100"
            borderRadius={5}
            py={2}
            px={4}
          />
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          mt={6}
          isDisabled={isUpdateDisabled}
        >
          Update Settings
        </Button>
      </form>
    </Box>
  );
};

export default DashboardAccount;