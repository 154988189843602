import React, { useState } from "react";
import {
  Box,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  FormControl,
  FormLabel,
  Button,
  Heading,
  IconButton,
  Image,
  Flex,
  Spacer,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  VStack,
  HStack,
  Divider,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FaHome, FaMapMarkerAlt, FaEnvelope, FaPhone, FaUser } from "react-icons/fa";
import { ChevronLeftIcon, ChevronDownIcon } from "@chakra-ui/icons";
import addressImage from "../../../../../assets/people.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../../utils/AuthContext';
import * as XLSX from "xlsx";

const stateAbbreviations = [
  "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA",
  "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MD",
  "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ",
  "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI", "SC",
  "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY",
];

const AddressSearch = () => {
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state_abv, setState] = useState("");
  const [zip, setZip] = useState("");
  const { frontendApiKey } = useAuth();
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Helper function to process values
  const processValue = (value) => value.toLowerCase().replace(/[^a-z0-9]+/g, '-');

  // Handler for the Search button
  const handleSearch = async () => {
    setResponseData(null);
    setError(null);
    setLoading(true);

    // Combine city, state abbreviation, and zip into one variable
    const cityStateZip = `${city}, ${state_abv} ${zip}`.trim();
    const processedStreet = processValue(street);
    const processedCityStateZip = processValue(cityStateZip);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/internal/people/address`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": frontendApiKey,
        },
        body: JSON.stringify({
          street: processedStreet,
          city_state_zip: processedCityStateZip,
          matching: false,
        }),
      });

      if (response.status === 405) {
        window.location.href = "/pricing?upgrade=true";
        return;
      }

      if (!response.ok) {
        let errorMessage = `Error: ${response.status} ${response.statusText}`;
        try {
          const errorData = await response.json();
          if (errorData.message) {
            errorMessage = `Error: ${errorData.message}`;
          }
        } catch (parseError) {}
        throw new Error(errorMessage);
      }

      const data = await response.json();
      setResponseData(data);
    } catch (err) {
      setError(err.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  // Handler for the Back button
  const handleBackClick = () => {
    navigate(-1);
  };

  const isFormComplete = street && city && state_abv && zip;

  // Function to flatten the data (similar to PeopleSearch)
  const flattenData = (data) => {
    let maxEmails = 0;
    let maxAkas = 0;
    let maxPhones = 0;

    data.forEach(person => {
      const emailCount = (person.email ? 1 : 0) + (person.other_emails ? person.other_emails.length : 0);
      const akaCount = person.akas ? person.akas.length : 0;
      const phoneCount = (person.primaryPhone && person.primaryPhone.primary ? 1 : 0) + (person.otherPhones ? person.otherPhones.length : 0);

      if (emailCount > maxEmails) maxEmails = emailCount;
      if (akaCount > maxAkas) maxAkas = akaCount;
      if (phoneCount > maxPhones) maxPhones = phoneCount;
    });

    return data.map(person => {
      const flattened = {
        name: person.name || "",
        firstName: person.firstName || "",
        lastName: person.lastName || "",
        age: person.age || "",
        address: person.address || "",
        // Add other static fields as needed
      };

      // Emails
      let emailIndex = 1;
      if (person.email) {
        flattened[`email_${emailIndex}`] = person.email;
        emailIndex++;
      }
      if (person.other_emails && person.other_emails.length > 0) {
        person.other_emails.forEach(email => {
          flattened[`email_${emailIndex}`] = email;
          emailIndex++;
        });
      }
      for (; emailIndex <= maxEmails; emailIndex++) {
        flattened[`email_${emailIndex}`] = "";
      }

      // Akas
      let akaIndex = 1;
      if (person.akas && person.akas.length > 0) {
        person.akas.forEach(aka => {
          flattened[`aka_${akaIndex}`] = aka;
          akaIndex++;
        });
      }
      for (; akaIndex <= maxAkas; akaIndex++) {
        flattened[`aka_${akaIndex}`] = "";
      }

      // Phones
      let phoneIndex = 1;
      if (person.primaryPhone && person.primaryPhone.primary) {
        flattened[`phone_${phoneIndex}`] = person.primaryPhone.primary.number;
        flattened[`phone_from_${phoneIndex}`] = person.primaryPhone.primary.type;
        phoneIndex++;
      }
      if (person.otherPhones && person.otherPhones.length > 0) {
        person.otherPhones.forEach(phone => {
          flattened[`phone_${phoneIndex}`] = phone.number;
          flattened[`phone_from_${phoneIndex}`] = phone.type;
          phoneIndex++;
        });
      }
      for (; phoneIndex <= maxPhones; phoneIndex++) {
        flattened[`phone_${phoneIndex}`] = "";
        flattened[`phone_from_${phoneIndex}`] = "";
      }

      return flattened;
    });
  };

  // Function to convert JSON to CSV manually
  const jsonToCSV = (jsonData) => {
    if (!jsonData || jsonData.length === 0) {
      return "";
    }

    const keys = Object.keys(jsonData[0]);
    const csvRows = [];

    // Add header row
    csvRows.push(keys.join(","));

    // Add data rows
    jsonData.forEach(obj => {
      const values = keys.map(key => {
        const escaped = ('' + obj[key]).replace(/"/g, '""');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  };

  // Function to handle downloads
  const handleDownload = (format) => {
    if (!responseData || responseData.length === 0) {
      alert("No data available to download.");
      return;
    }

    // Generate timestamp in YYYYMMDD_HHMMSS format
    const now = new Date();
    const timestamp = now.toISOString().replace(/[:.]/g, '-').split('T')[0] + '_' +
                      now.toTimeString().split(' ')[0].replace(/:/g, '');
    
    const processedStreet = processValue(street);
    const filenameBase = `${processedStreet}_address_search_${timestamp}`;

    if (format === "json") {
      const dataStr = JSON.stringify(responseData, null, 2);
      const blob = new Blob([dataStr], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${filenameBase}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else if (format === "csv" || format === "excel") {
      // Flatten data for CSV and Excel
      const flattenedData = flattenData(responseData);

      if (format === "csv") {
        // Convert JSON to CSV
        const csv = jsonToCSV(flattenedData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${filenameBase}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } else if (format === "excel") {
        // Convert JSON to Excel
        const worksheet = XLSX.utils.json_to_sheet(flattenedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Results");
        XLSX.writeFile(workbook, `${filenameBase}.xlsx`);
      }
    } else {
      alert("Unsupported format.");
    }
  };

  return (
    <Box w="850px" mx="auto" mt={10} mb={100}>
      <Heading mb={6}>
        <Flex alignItems="center">
          <IconButton
            icon={<ChevronLeftIcon />}
            aria-label="Go back"
            mr={4}
            variant="ghost"
            fontSize="xl"
            _hover={{ transform: "translateX(-4px)" }}
            onClick={handleBackClick}
          />
          <Image src={addressImage} alt="Address logo" boxSize="40px" mr={4} />
          Address Search Data
          <Spacer />
        </Flex>
      </Heading>

      {/* Search Form Section */}
      <Box>
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          {/* Street Input */}
          <FormControl id="street" isRequired>
            <FormLabel fontWeight="bold">Street</FormLabel>
            <InputGroup bg="gray.100" borderRadius="md">
              <InputLeftElement pointerEvents="none">
                <FaHome color="gray" />
              </InputLeftElement>
              <Input
                placeholder="Street Address"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          {/* City Input */}
          <FormControl id="city" isRequired>
            <FormLabel fontWeight="bold">City</FormLabel>
            <InputGroup bg="gray.100" borderRadius="md">
              <InputLeftElement pointerEvents="none">
                <FaMapMarkerAlt color="gray" />
              </InputLeftElement>
              <Input
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </InputGroup>
          </FormControl>

          {/* State Select */}
          <FormControl id="state" isRequired>
            <FormLabel fontWeight="bold">State</FormLabel>
            <InputGroup bg="gray.100" borderRadius="md">
              <InputLeftElement pointerEvents="none">
                <FaMapMarkerAlt color="gray" />
              </InputLeftElement>
              <Select
                placeholder="Select State"
                pl={10}
                value={state_abv}
                onChange={(e) => setState(e.target.value)}
              >
                {stateAbbreviations.map((stateAbbr) => (
                  <option key={stateAbbr} value={stateAbbr}>
                    {stateAbbr}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </FormControl>

          {/* ZIP Code Input */}
          <FormControl id="zip" isRequired>
            <FormLabel fontWeight="bold">ZIP Code</FormLabel>
            <InputGroup bg="gray.100" borderRadius="md">
              <InputLeftElement pointerEvents="none">
                <FaMapMarkerAlt color="gray" />
              </InputLeftElement>
              <Input
                placeholder="ZIP Code"
                value={zip}
                onChange={(e) => setZip(e.target.value)}
                type="number"
                maxLength={10}
              />
            </InputGroup>
          </FormControl>
        </Grid>

        <Box mt={4}>
          <Text color="gray.600" fontSize="md">
            Find residents for <strong>50</strong> credits.
          </Text>
        </Box>

        <Box mt={6}>
          <Button
            size="lg"
            colorScheme="blue"
            onClick={handleSearch}
            isDisabled={!isFormComplete || loading}
            leftIcon={loading && <Spinner size="sm" />}
          >
            {loading ? "Searching..." : "Search Address"}
          </Button>
        </Box>

        {error && (
          <Box mt={6}>
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          </Box>
        )}

        {responseData && (
          <>
            {responseData.length > 0 ? (
              <Box mt={8}>
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                  <Heading size="lg">
                    Results
                  </Heading>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rightIcon={<ChevronDownIcon />}
                      size="md"
                      colorScheme="green"
                      mt={2}
                    >
                      Download
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => handleDownload("json")}>
                        JSON
                      </MenuItem>
                      <MenuItem onClick={() => handleDownload("csv")}>
                        CSV
                      </MenuItem>
                      <MenuItem onClick={() => handleDownload("excel")}>
                        Excel
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                <VStack spacing={4} align="stretch">
                  {responseData.map((person, index) => (
                    <Box
                      key={index}
                      p={4}
                      bg="gray.100"
                      borderWidth="1px"
                      borderRadius="xl"
                    >
                      <Flex alignItems="center" mb={2}>
                        <FaUser size="24px" color="blue.500" />
                        <Box ml={3} flex="1">
                          <Flex alignItems="center">
                            <Text fontSize="xl" fontWeight="bold">
                              {person.name}
                            </Text>
                            <Spacer />
                            <Text fontSize="2xl" fontWeight="bold">
                              Age: {person.age}
                            </Text>
                          </Flex>
                          <HStack mt={1} spacing={1} alignItems="center">
                            <FaMapMarkerAlt color="red.500" />
                            <Text>{person.address}</Text>
                          </HStack>
                        </Box>
                      </Flex>
                      <Divider mb={4} />
                      <Accordion allowToggle>
                        <AccordionItem>
                          <AccordionButton>
                            <Box flex="1" textAlign="left" fontWeight="bold">
                              Contact Information
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            {/* Phone Numbers */}
                            {person.phone || (person.otherPhones && person.otherPhones.length > 0) ? (
                              <Box mb={4}>
                                <Text fontWeight="bold" mb={2}>
                                  Phone Numbers:
                                </Text>
                                <VStack align="start" spacing={2}>
                                  {/* Primary Phone */}
                                  {person.primaryPhone && person.primaryPhone.primary && (
                                    <HStack>
                                      <FaPhone color="blue.500" />
                                      <Text>
                                        {person.primaryPhone.primary.number} ({person.primaryPhone.primary.type})
                                      </Text>
                                    </HStack>
                                  )}
                                  {/* Other Phones */}
                                  {person.otherPhones && person.otherPhones.length > 0 && person.otherPhones.map((phone, idx) => (
                                    <HStack key={idx}>
                                      <FaPhone color="blue.500" />
                                      <Text>
                                        {phone.number} ({phone.type})
                                      </Text>
                                    </HStack>
                                  ))}
                                </VStack>
                              </Box>
                            ) : null}
                            {/* Emails */}
                            {person.email || (person.other_emails && person.other_emails.length > 0) ? (
                              <Box>
                                <Text fontWeight="bold" mb={2}>
                                  Emails:
                                </Text>
                                <VStack align="start" spacing={2}>
                                  {/* Primary Email */}
                                  {person.email && (
                                    <HStack>
                                      <FaEnvelope color="blue.500" />
                                      <Link href={`mailto:${person.email}`} color="blue.500" isExternal>
                                        {person.email}
                                      </Link>
                                    </HStack>
                                  )}
                                  {/* Other Emails */}
                                  {person.other_emails && person.other_emails.length > 0 && person.other_emails.map((email, idx) => (
                                    <HStack key={idx}>
                                      <FaEnvelope color="blue.500" />
                                      <Link href={`mailto:${email}`} color="blue.500" isExternal>
                                        {email}
                                      </Link>
                                    </HStack>
                                  ))}
                                </VStack>
                              </Box>
                            ) : null}
                            {/* Additional Information (Optional) */}
                            {person.middleNames && person.middleNames.length > 0 && (
                              <Box mt={4}>
                                <Text fontWeight="bold" mb={1}>
                                  Middle Names:
                                </Text>
                                <Text>{person.middleNames.join(", ")}</Text>
                              </Box>
                            )}
                            {person.akas && person.akas.length > 0 && (
                              <Box mt={2}>
                                <Text fontWeight="bold" mb={1}>
                                  Also Known As:
                                </Text>
                                <Text>{person.akas.join(", ")}</Text>
                              </Box>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </Box>
                  ))}
                </VStack>
              </Box>
            ) : (
              <Box mt={6}>
                <Alert status="info">
                  <AlertIcon />
                  No results found.
                </Alert>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default AddressSearch;
