import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import LoginMain from './LoginMain';


function Login() {
  return (
    <>
      <Header />
      <LoginMain />
      <Footer/>
    </>
  );
}

export default Login;
