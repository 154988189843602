import React from "react";
import { Box, Heading, Button, useToast, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { signOut } from 'firebase/auth';
import { auth } from '../../../Firebase';

const DashboardMain = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
      toast({
        title: "Logged out successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Logout failed", error.message);
      toast({
        title: "Logout failed",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const tabIndex = () => {
    if (location.pathname.includes('account')) return 0;
    if (location.pathname.includes('apikey')) return 1;
    if (location.pathname.includes('subscription')) return 2;
    return 0;
  };

  const handleTabChange = (index) => {
    switch (index) {
      case 0:
        navigate('/dashboard/account');
        break;
      case 1:
        navigate('/dashboard/apikey');
        break;
      case 2:
        navigate('/dashboard/subscription');
        break;
      default:
        navigate('/dashboard');
    }
  };

  return (
    <Box mt={20} mb="300px">
      <Heading mb={6} textAlign="center">
        Dashboard
      </Heading>
      <Box
        maxWidth="600px"
        margin="auto"
        pt={20}
        pb={60}
        bg="white"
        p={6}
        borderRadius={10}
        boxShadow="lg"
      >
        <Tabs index={tabIndex()} onChange={handleTabChange} variant="soft-rounded" colorScheme="blue">
          <TabList mb={6}>
            <Tab as="button" mr={2}>Account</Tab>
            <Tab as="button" mr={2}>API Key</Tab>
            <Tab as="button">Subscription Details</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Outlet />
            </TabPanel>
            <TabPanel>
              <Outlet />
            </TabPanel>
            <TabPanel>
              <Outlet />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Box display="flex" justifyContent="center" mt={6} maxWidth="600px" margin="auto">
        <Button
          colorScheme="red"
          onClick={handleLogout}
          width="100%"
          borderWidth={3}
          borderColor={'#222222'}
          borderRightWidth={6}
          borderBottomWidth={6}
          borderRadius={10}
          mt={6}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardMain;