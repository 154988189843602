import React from 'react';
import Header from '../../widgets/Header';
import Footer from '../../widgets/Footer';
import PrivacyMain from './PrivacyMain';

function Privacy() {
  return (
    <>
      <Header />
      <PrivacyMain />
      <Footer/>
    </>
  );
}

export default Privacy;
